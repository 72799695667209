.products {
    margin-top: 170px;
}

.products-list{
   overflow: hidden;
}
.col-product {
  padding-left: 20px !important;
}


.card-grid {
  margin: 3px;
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 20px;
  justify-items: center;
}

.card-product {
  height: 100% !important;
  width: 100% !important;
  box-shadow: 0px 0px 9px 0px #0000000D;
}

.total {
  text-align: center;
  margin-top: -10px;
  font-size: 14px;
  color: gray;
}
.banner {
  width: 100%;
  /* Add any other styles for the banner container */
}

.banner-image {
  width: 100%;
  height: 130px;

  /* Add any other styles for the banner image */
}


/* Responsive Sidebar Filters */ 

.products .sidebar-responsive-container {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    display: flex;
    z-index: 1030;
    pointer-events: none;
    /* Disable interactions by default */
}

.products .sidebar-responsive-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    opacity: 0;
    transition: opacity 0.3s ease;
}

.products .sidebar-responsive {
    position: relative;
    width: 300px;
    padding: 20px 15px;
    background: #fff;
    min-height: 100vh;
    transform: translateX(-300px);
    transition: transform 0.3s ease;
    overflow-y: scroll;
}

/* Active state to show sidebar */
.products .sidebar-responsive-container.active {
    pointer-events: auto;
}

.products .sidebar-responsive-container.active .sidebar-responsive-overlay {
    opacity: 1;
}

.products .sidebar-responsive-container.active .sidebar-responsive {
    transform: translateX(0);
}

/* End Responsive Sidebar Filters */ 

.show-filters-container {
  display: none;
  width: 100%;
  background: #fff;
  position: fixed;
  top: 100px;
  padding: 10px 0 20px;
  left: 0;
  z-index: 1;
}

.show-filters {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  border: 1px solid var(--secondary-color);
  border-radius: 5px;
  padding: 14px 20px;
}

.show-filters-title {
  font-family: "Inter300";
  color: #000;
  font-size: 16px;
  margin: 0;
}

.show-filters svg {
  width: 25px;
  height: 20px;
}

.sidebar-responsive .filters {
  width: 100% !important;
}

/* Product list filters */
.product-list-filters {
  display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    margin-bottom: 15px;
}
.filters-display {
  display: flex;
  align-items: center;
  gap: 10px;
}
.product-list-filters .seperator {
  background-color:#E4E4E4;
  width: 1px;
  height: 15px;
}
.product-list-filters .title {
  margin-top: unset !important;
}
.product-list-filters .h1 {
  margin-bottom: unset !important;
}
.offers-div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.offers-text {
  font-family: "Poppins600";
  color: #FD4B6B;
  font-size:15px;
  font-weight: 600;

}
.offers-div  .form-check-input[type=checkbox] {
  border-radius: 0;
}
.sort-text {
  color: #353434;
  font-size: 15px;
  font-weight: 400;
  padding:0px 7px;

}
/* دائرة تلتف حول نفسها */
.circle-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* ملء الشاشة */
}

.offers-div  .circle {
  background: #FD4B6B;
  width: 20px;
  height: 20px;
  border-radius: 50%; /* لتحويل الشكل إلى دائرة */
  position: relative; /* لتحديد مكان النص داخل الدائرة */
  animation: spin 5s linear infinite; /* تدوير الدائرة */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
}

.offers-div .percent {
  position: absolute; /* لتثبيت النص داخل الدائرة */
  font-size: 11px;
  font-weight: 700;
  color:#fff;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.spin {
  animation: spin 7s linear infinite;
}


/* Hide the default checkbox */
.custom-checkbox-container input[type="checkbox"] {
  display: none;
}

/* Style the custom checkbox */
.custom-checkbox-container {
  position: relative;
  display: inline-block;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

/* Create a square for the checkbox */
.custom-checkbox-container .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  border: 1px solid #D2D2D2;
  border-radius: unset;
  transition: background-color 0.3s, border-color 0.3s;
}

/* When checked, change background and add a checkmark */
.custom-checkbox-container input[type="checkbox"]:checked + .checkmark {
  background-color: white;
}

.custom-checkbox-container input[type="checkbox"]:checked + .checkmark::after {
  content: "";
  position: absolute;
  left: 4px;
  top: 0px;
  width: 6px;
  height: 12px;
  border: 2px solid black;
  border-radius: 4px;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}


.sort-container {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.sort-header {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sort-menu-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

.sort-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  background: white;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 10px;
  min-width: 200px;
  z-index: 1000;
}

.ordering-dropdown {
  left: auto;
  right: 0;
}

.sort-section {
  margin-bottom: 10px;
}

.sort-label {
  font-weight: bold;
  margin-bottom: 5px;
  display: block;
}

.sort-group {
  margin-bottom: 5px;
}

.sort-option {
  padding: 5px;
  cursor: pointer;
}

.sort-option:hover {
  background-color: #f0f0f0;
}

.sort-option.selected {
  font-weight: bold;
  color: #007bff;
}



  @media (max-width: 1400px) {

  }
  
  
  @media (max-width: 1200px) {
    .card-grid {
      grid-template-columns: repeat(3, 1fr);
    }
  }
  
  
  @media (max-width: 992px) {
    .show-filters-container {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .products {
      margin-top: 170px;
    }
    .filters-big-screen {
      display: none;
    }
 
  
  }
  
  @media (max-width: 768px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 576px) {
    .card-grid {
      grid-template-columns: repeat(2, 1fr);
    }
  }
  
  @media (max-width: 476px) {
    .card-grid {
      grid-template-columns: repeat(1, 1fr);
    }
    .product-list-filters {
      gap: 0px;
    }
    .offers-text {
      font-size:12px;
    }
    .sort-text {
      font-size: 12px;
      padding:0px 2px;
    }
    .custom-checkbox-container .checkmark {
      width:10px;
      height:10px;
    }
    .custom-checkbox-container {
      width:10px;
      height:10px;
    }
    .filters-display {
      gap:5px;
    }
    .title h1 {
      font-size: 19px;
    }
    .category-name {
      font-size: 13px;
    }
  }

  /* customize bootstrab container */
@media (min-width: 576px) {
  .products .container {
    max-width: 560px; /* Slightly bigger than default 540px */
  }
}

@media (min-width: 768px) {
  .products .container {
    max-width: 760px; /* Slightly bigger than default 720px */
  }
}

@media (min-width: 992px) {
  .products .container {
    max-width: 980px; /* Slightly bigger than default 960px */
  }
}

@media (min-width: 1200px) {
  .products .container {
    max-width: 1200px; /* Slightly bigger than default 1140px */
  }
}

@media (min-width: 1400px) {
  .products  .container {
    max-width: 1400px; /* Slightly bigger than default 1320px */
  }
}

@media (min-width: 1500px) {
  .products  .container {
    max-width: 1500px; /* Slightly bigger than default 1320px */
  }
}

@media (min-width: 1600px) {
  .products .container {
    max-width: 1430px; /* Slightly bigger than default 1320px */
  }
}

@media (min-width: 1700px) {
  .products  .container {
    max-width: 1700px; /* Slightly bigger than default 1320px */
  }
}
  

