.login {
    margin-top: 120px;
    margin-bottom: -120px;
}

.login-container {
    position: relative;
    margin-bottom: 120px;
}


.row {
    margin-right: 0px !important;
    margin-left: 0px !important;
}

.row-padding {
    padding-top: 80px;
}

.back {
    background-image: url('../../asset/images/Sign/sign-backg.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}


.login-back {
    background-image: url('../../asset/images/Sign/sign-image.png');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 50%;
}

.sign-div {
    margin-right: 80px;
    margin-top: 50px;
    margin-bottom: 50px;
}

.sign-title {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
}

.sign-subtitle {
    font-family: 'Inter';
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0.25px;
    text-align: left;
    color: #777777;
}

.sign-icon-div {
    position: absolute;
    top: 6px;
    left: 15px;
}
.faEye-icon {
    position: absolute;
    top: 6px;
    right: 15px;
}

.log-input {
    background: #FFFFFF !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 5px !important;
    padding: 5px 35px !important;
    font-style: normal !important;
    color: #000000;
    font-family: Inter;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0.25px !important;
}

.margin-input {
    margin-top: 10px;
}

.p-checkbox {
    /* width: 15px;
    height: 15px;
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    align-items: center; */
    width: 15px;
    margin-top: -7px;
    height: 15px;
    /* border: 1px solid #D1D1D1; */
    border-radius: 5px;

}

.display-row {
    display: flex;
    justify-content: space-between;
    padding-top: 15px
}

.forget-text {
    font-family: Inter;
    font-size: 13px;
    font-weight: 600;
    letter-spacing: 0.25px;
    color: rgba(16, 23, 40, 1);
}

.dashed-line {
    border-top: 1px dashed #00000047;
    margin-top: 20px;
    margin-bottom: 25px;
}

.sign-button {
    border-radius: 5px;
    background: rgba(16, 23, 40, 1);
    color: white;
    padding: 7px 20px;
    border: none;
    cursor: pointer;
    width: 100%;
    margin-bottom: 10px;
    font-size: 16px;
}

.display-google-button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.account-text {
    font-family: Inter;
    font-size: 14px;
    font-weight: 500;
    /* line-height: 20px; */
    letter-spacing: 0.30px;
    color: #1A1A1A;
    text-align: center;
}

.account-text span {
    font-weight: 900;
}

.account-text a {
    color: #101728;
    font-weight: 800;
}

.wrapper {
    display: grid;
    grid-template-columns: 2.5fr 3fr;
    /* grid-gap: 20px;*/
}

.invalid-feedback {
    font-size: 11px !important;
    display: unset !important;
    margin-top: unset !important;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {
    .login {
        margin-top: 90px;
    }

    .wrapper {
        grid-template-columns: 3fr 2fr;
    }

    .sign-title {
        font-size: 25px;
    }

    .sign-subtitle {
        font-size: 12px;
    }

    .log-input {
        font-size: 12px !important;
    }

    .forget-text {
        font-size: 11px;
    }

    .sign-button {
        font-size: 14px;
    }

    .account-text {
        font-size: 11px;
    }

    .login-back {
        width: 40%;
    }
}

@media (max-width: 767px) {
    .wrapper {
        grid-template-columns: 1fr 0fr;
    }

    .sign-div {
        margin-left: 30px;
        margin-right: 20px;
    }

    .login-back {
        display: none;
    }
}

@media (max-width: 567px) {
    .log-input {
        width: 100% !important;
    }

    .login {
        margin-bottom: -60px;
    }
}

@media (max-width: 492px) {}