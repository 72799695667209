.pay {
    margin-top: 220px;
    padding: 70px 0; /*100*/
    width: 100%;
    background: url("../../../../asset/images/Home/moreTime.png"), lightgray -0.509px 0px / 100.052% 126.534% no-repeat;
    background-size: cover;
}


.pay-content h1 {
    color: #292929;
    font-size: 50px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
}

.pay-content p {
    width: 90%; /*85*/
    color: #292929;
    text-align: justify;
    font-family: "Inter400";
    font-size: 18px;
    line-height: 30px; /* 166.667% */
    margin: 20px 0 !important;
}
.pay-content h1 {
font-size:40px;
}
/* .pay-content span {
    font-weight: 700;
} */

.pay-img {
    position: relative;
    height: 100%;
}
.pay-img img {
    position: absolute;
    width: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media (max-width: 1200px) {
    .pay-content h1 {
        font-size: 42px;
    }
    
    .pay-content p {
        font-size: 20px;
    }
}
@media (max-width: 992px) {
    .pay-responsive {
        display: flex;
        justify-content: center !important;
        align-items: center !important;
        flex-direction: column-reverse !important;
        gap: 280px !important;
    }
    .pay-img img {
        width: 230px;
        top: 0;
    }
    .pay-content {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .pay-content p {
        text-align: center !important;
    }
}
@media (max-width:480px) {
    .pay-img img {
        width: 200px;
        top: 30px;
    }
    
}
