.details-div {}

.title-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.details-title h1 {
  margin: 0;
  position: relative;
  color: var(--main-color);
  font-size: 32px;
  font-style: normal;
  font-family: "Inter400";
  text-transform: capitalize;
}

.details-title h1::before {
  position: absolute;
  content: '';
  background-color: var(--secondary-color);
  width: 6px;
  height: 60%;
  left: -16px;
  top: 50%;
  transform: translateY(-50%);
}

.details-title p {
  color: var(--main-color);
  font-size: 17px;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
  margin-bottom: 10px;
}


.product-rate {
  display: flex;
  align-items: center;
}

.product-rate-icon {
  margin-right: 10px;
  margin-top: -5px;
}

.product-rate .value {
  font-family: "Inter700";
  font-size: 16px;
  padding-right: 4px;
}

.product-rate i {
  color: #ECC77C;
  font-size: 15px;
}

.product-text {
  font-family: "Quicksand500" !important;
  font-size: 16px;
  text-align: left;
}

.product-text-img {
  width: 90px;
}

.product-subtext-title {
  font-family: "Quicksand300";
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  padding-right: 5px;
  padding-left: 5px;
}

.product-subtext {
  margin-top: 20px;
  font-family: "Quicksand300";
  font-size: 16px;
  line-height: 28px;
  text-align: left;
  padding-right: 10px;
}

.after-price p {
  font-family: "Quicksand400";
  font-size: 16px;
  text-align: left;
  color: var(--secondary-color);
  margin-bottom: unset;

}

.after-price span {
  font-family: "Quicksand400";
  font-size: 32px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #000000;
  margin-left: 6px;
}


.before-price p {
  font-family: Quicksand400;
  font-size: 18px;
  font-weight: 400;
  letter-spacing: 0em;
  text-align: left;
  color: #BABABA;
  padding-left: 15px;
  margin-bottom: unset;
}

.before-price span {
  text-decoration: line-through;
  font-size: 32px;
  font-family: "Quicksand400";
  text-align: left;
  margin-left: 6px;
}

.sale-price {
  background-color: #C1C1C130;
  padding: 5px 25px;
  margin-left: 20px;
  border-radius: 5px;
  font-family: "Quicksand400";
  font-size: 16px;
  color: #101728;
}

.option-list {
  list-style-type: none;
  display: flex;
  padding: 0;
  flex-wrap:wrap;
}

.color-item {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 6px;
  margin-bottom: 15px;
  margin-top: 15px;
}

.selected {
  border: 2px solid var(--secondary-color) !important;
}

.size-item {
  background-color: #F4F4F4;
  font-family: Inter;
  font-size: 14px;
  font-weight: 300;
  line-height: 25px;
  /* letter-spacing: 0em; */
  text-align: center;
  color: #1C1C1C;
  margin-right: 20px;
  padding: 5px 15px;
  border: none;
  /* margin-bottom: 15px; */
  margin-top: 15px;
}

.add-to-cart {
  color: #FFF;
  text-align: center;
  font-family: "Roboto400";
  font-size: 16px;
  letter-spacing: 0.25px;
}

.image-slider-container {
  height: auto;
}


.MuiRating-root {
  color: var(--secondary-color) !important;
}

.custom-tabs .nav-tabs {
  border: none !important;
}

.custom-tabs .nav-link {
  border-bottom: 2px solid transparent;
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  color: black;
}

.custom-tabs .nav-link.active {
  border-bottom: 2px solid black !important;
  border-top: unset !important;
  border-left: unset !important;
  border-right: unset !important;
  font-weight: 900;
}

.tabs-information{
  margin-top: 120px;
  /* margin-bottom: 75px; */
}

.tabs-information .nav-link {
  color: #000000 !important;
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  line-height: 22px;
  margin-right: 50px;

}

.tabs-information .active {
  color: #000000 !important;
}

.tabs-information .active p {
  color: #1C1C1C;
  /* padding-top: 20px; */
  font-family: "Quicksand300";
  font-size: 16px;
  line-height: 31px;
  text-align: justify;

}


.size-item.disabled {
  color: #f37f3d38;
}

.custom-class{
  /* color: yellow !important; */
  background-color: #f37f3d38;

}

.cart-link  {
  margin-top: 50px;
  /* width: 100%; */
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  background: var(--main-color);
  width: 100%;
  font-family: "Calibri";
  font-size: 17px;
  font-style: normal;
  padding: 12px 50px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;
  transition: .3s ease-in-out;
}
.cart-link span{
  color:  var(--secondary-color);

}


.input-group-container {
  display: flex;
  align-items: center !important;
  padding-top: 15px;
  justify-content: end !important;
}

.button-minus,
.button-plus {
  background-color: var(--main-color); /* Replace maincolor with your desired color value */
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  width: 35px;
  height: 35px;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.button-minus {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.button-plus {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.quantity-field {
  width: 60px;
  text-align: center;
  height: 35px;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #303030;
  font-family: "Inter300";
  font-size: 14px;
  letter-spacing: 1.44px;
  text-transform: uppercase;
}

.quantity-text{
  font-family: "Inter400";
  text-align: end;
  font-size: 19px;
  letter-spacing: 4.18px;
  text-transform: uppercase;
  color: #000000;
}

.deatil-specifications-container {
  display: flex;
  justify-content: start;
  align-items: center;
  margin-top: 5px;
  gap: 6px;
  margin-top: 10px;
}

.deatil-specifications-label {
  color: #1C1C1C;
  text-align: justify;
  font-family: "Inter600";
  font-size: 16px;
  text-transform: capitalize;
}

.deatil-specifications-description {
  color: #1C1C1C;
  font-family: "Inter300";
  font-size: 16px;
  text-transform: capitalize;
}

@media (max-width: 1250px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {
  .image-slider-container {
    margin-top: 40px !important;
  }
  .details-title h1{
    font-size: 30px;
  }
  .cart-link a{
    margin-bottom: 50px;

  }
}

@media (max-width: 768px) {
}

@media (max-width: 567px) {
  .details-title p {
    font-size: 16px;
  }
  .details-title h1 {
    font-size: 30px;
  }
}

@media (max-width: 400px) {
  .quantity-text {
    font-size: 17px;
  }
  .sale-price{
    font-size: 14px;
  }
  .after-price span, .before-price span {
    font-size: 28px;
  }
}