.feedback-slide {
    position: relative;
    border-radius: 5px;
    border: 1px solid #CECECE;
    padding: 30px 20px 50px;

    margin: 0 10px !important;
  }
  
  .feedback-slide svg {
    margin-right: 2px;
    margin-bottom: 15px;
  }
  
  .feedback-slide p {
    color: #454545;
    text-align: justify;
    font-size: 15px;
    width: 90%;
    font-family: "Poppins300";
    line-height: 30px;
  }
  
  .feedback-img {
    position: absolute;
    top: calc(100% - 60px);
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
  }
  .feedback-img img {
    width: 81px;
    clip-path: circle();
    margin-bottom: -10px;
    height: 100px;
  }
  
  .feedback-img h1 {
    margin: 0;
    color: #9B9B9B;
    text-align: center;
    font-size: 15px;
    font-family: "Inter";
    letter-spacing: 1px;
    text-transform: uppercase;
  }
  
  .feedback-img i {
    color: #F1AE00;
    font-size: 12px;
  }

  @media (max-width: 425px) {
    .feedback-slide p {
        text-align: center;
    }
    .feedback-slide {
        padding: 30px 20px 65px;
    }    
  }

  @media (max-width: 1200px) {
    
  }

  @media (max-width: 992px) {
 
  }

  @media (max-width: 476px ) {
    .feedback-slide p {
        font-size: 14px;
    }
    .slick-slide {
        margin: 0 1px !important;
    }
  }