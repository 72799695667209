
.categories-slider {
 overflow: hidden ;
 margin-bottom: 38px;
 margin-top: 0px;
}
.categories-slider .slick-slider {
  margin-right: 20px; /* Adjust the margin as needed */
}

.categories-slider .slick-list {
  margin: 0 -10px; /* Adjust the negative margin as needed */
}


@media (max-width: 1400px) {

}


@media (max-width: 1200px) {

}


@media (max-width: 992px) {
  .categories-slider{
    margin-top:25px;
  }

}

@media (max-width: 692px){


}  

@media (max-width: 567px) {

}

@media (max-width: 492px) {
}



  
