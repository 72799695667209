.header {
    position: relative;
    padding: 40px 0;
    background: rgba(0, 0, 0, 0.65) !important;
    overflow: hidden;
    margin-top: 140px;
}
.header-img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
}

.header-title {
    color: #FFF;
    font-family: "Poppins200";
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    letter-spacing: 1px;
    text-transform: uppercase;
}
.header-title span {
    font-family: "Poppins400";
}

.header-desc {
    margin-top: 20px;
    color: #FFF;
    font-family: "Poppins400";
    font-size: 28px;
    letter-spacing: -0.6px;
    text-transform: uppercase;
}