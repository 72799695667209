/* .input-div {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.input-range {
    width: 100px;
    border: 1px solid #E9E9E9;
    border-radius: 5px;
    font-size: 12px;
    font-family: Inter;
    font-weight: 300;
    line-height: 14px;
    letter-spacing: 0.09em;
    text-align: left;
    color: #D0D0D0;
    padding: 7px;
}
.input-range:focus,
.input-range:active,
.input-range::placeholder {
  color: black;
}

.sign-range {
    font-size: 30px;
    font-family: Inter;
    font-weight: 300;
    line-height: 14px;
    color: #D0D0D0;
    text-align: center;
}

input[type="range"]::-webkit-slider-runnable-track { 
    -webkit-appearance: none; 
    box-shadow: none; 
    border: none; 
    background: yellow; 
    }

.double-slider-box {
    background-color: #fff;
    padding: 20px 40px;
    border-radius: 10px;
    max-width: 20rem;
}

h3.range-title {
    margin-bottom: 4rem;
}

.range-slider {
    position: relative;
    width: 100%;
    height: 5px;
    margin: 30px 0;
    background-color: #8a8a8a;
}

.slider-track {
    height: 100%;
    position: absolute;
    background-color: #fe696a;
}
 */


 .products-sidebar-accordion-container .css-7iurs7-MuiAccordionSummary-content{
    margin: unset !important;
}

.products-sidebar-accordion-container{
    width: 100%;
}
 .products-sidebar-accordion-container {
    /* border-bottom: 1px solid #E7E7E7; */
    padding-bottom: 10px;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.products-sidebar-accordion-container .products-sidebar-accordion-child {
    --level: calc(var(--level, 0) + 1);
}

.products-sidebar-range {
    margin-top: 30px;
    border-radius: unset !important;
} 

.products-sidebar-accordion-container .MuiSlider-valueLabel {
    border-radius: 5px !important;
    background: var(--main-color) !important;
    color: #FFF !important;
    font-family: "Inter400" !important;
    font-size: 10px !important;
    letter-spacing: 1.2px !important;
    padding: 3px 12px !important;
    text-transform: uppercase !important;
}
.products-sidebar-accordion-container .MuiSlider-thumb  {
    padding: 0.8px !important;
    outline: rgb(180, 180, 180) solid 1px !important;
    background-color: var(--main-color) !important;
    border: 2px solid rgb(255, 255, 255) !important;
    width: 9px !important;
    height: 9px !important;
}

.products-sidebar-ranges {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: #B4B4B4;
    font-family: "Inter400";
    font-size: 22px;
}
.products-sidebar-range-input {
    width: 40%;
    padding: 3px 5px;
    border-radius: 5px;
    border: 1px solid #B4B4B4;
    color: #000;
    font-family: "Inter400";
    font-size: 12px;
    letter-spacing: 1.26px;
    outline: unset !important;
}
.products-sidebar-range-input::placeholder {
    color: #B4B4B4;
}
.products-sidebar-range-input:focus-visible {
    outline: var(--main-color) !important;
}
