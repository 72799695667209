.box {
    margin-top: 40px;
    border: 1px solid #ccc;
    border-radius: 20px;
    padding: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;
    position: relative;
    height: 90px;
  }
  
  .box::after {
    content: '';
    position: absolute;
    z-index: -10;
    width: calc(var(--bs-gutter-x) * .5 * 2.1);
    left: 100%;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background: #ccc;
  }
  .final-status {
    border: 3px solid #0ac2ac !important;
  }
  .final-status::after {
    display: none !important;
  }
  
  .box img {
    width: 35px;
  }
  .box-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 5px;
  } 
  .box-content h1 {
    margin: 0;
    font-size: 18px !important;
    font-family: "Calibri Bold";
  }
  .box-content h2 {
    margin: 0;
    font-size: 16px !important; 
  }
  
  
  @media (max-width: 768px) {
    .box::after {
        top: 100%;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 41px;
    }
  }