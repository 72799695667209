/* .slick-dots {
  bottom: 20% !important;
}
.slick-dots li {
  margin: unset;
}
.slick-dots li.slick-active button:before {
  opacity: .75;
  color: var(--main-color);
  background: none;
  border: 1px solid var(--main-color);
  border-radius: 50%;
  height: 13px;
  background: var(--main-color);
  width: 13px;
}
.slick-dots li button:before {
  border: 1px solid var(--main-color);
  border-radius: 50%;
  color: #fff;
  height: 13px;
  width: 13px;
}

@media (max-width: 476px) {
  .slick-dots {
    bottom: 25% !important;
  }
}

 */


/* Start Reviews */

.reviews {
  padding-bottom: 180px;
  overflow: hidden;
}

.feedback-slider {
  margin-top: 50px;
}


.slick-list {
  overflow: unset !important;
}
.slick-dots {
  bottom: 50px !important;
  overflow: unset !important;
}
.slick-dots li {
  margin: unset !important;
}
.slick-dots li.slick-active button:before {
  opacity: .75 !important;
  color: var(--main-color) !important;
  background: none !important;
  border: 1px solid var(--main-color) !important;
  border-radius: 50% !important;
  height: 13px !important;
  background: var(--main-color) !important;
  width: 13px !important;
}
.slick-dots li button:before {
  border: 1px solid var(--main-color) !important;
  border-radius: 50% !important;
  color: #fff !important;
  height: 13px !important;
  width: 13px !important;
}

@media (max-width: 476px) {
  .reviews {
    padding-bottom: unset !important;
  }
  .slick-dots {
     bottom: 110px !important;
  }
  .feedback-slide p {
      text-align: center !important;
  }
  .feedback-slide {
      padding: 30px 20px 65px !important;
  }    
}

/* End Reviews */


