.new-address-container {
    width: 100%;
    margin-top: 20px;
    padding-top: 20px;
    border-top: 2px solid rgba(232, 234, 238, 0.40);
}

.new-address-form-title {
    color: #101728;
    font-family: "Inter500";
    font-size: 16px;
    text-transform: capitalize;
}

.address-form {
    margin-top: 12px;
    border-top: 1px solid #A4A4A4;
}

.addres-btns {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.address-btn {
    padding: 7px;
    border-radius: 3px;
    min-width: 140px;
    text-align: center;
    font-family: "Roboto500";
    font-size: 13px;
    font-style: normal;
    letter-spacing: 0.25px;
    border: 1px solid var(--main-color);
    transition: .3s ease;
}
.address-btn:first-child {
    color: var(--main-color);
    background: #fff;
}
.address-btn:last-child {
    background: var(--main-color);
    color: #fff;
}
.address-btn:first-child:hover {
    background: var(--main-color);
    color: #fff;
}
.address-btn:last-child:hover {
    opacity: 0.7;
}

.address-map-container {
    margin-top: 20px;
    margin-bottom: 30px;
}