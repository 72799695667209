.category {
    margin-top: 50px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 15px;
    cursor: pointer;
}

.category-img {
    height: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100%;
    box-shadow: 0px 4px 35px 0px rgba(0, 0, 0, 0.05);
}
.category-img img {
    width: 85%;
    height: 80%;
    object-fit: contain;
} 
.category-img .color {
    width: 130px;
    z-index: -1;
    position: absolute;
    top: 0;
    left: 0;
    height: unset;
}

.category-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
}
.category-content h1 {
    color: var(--main-color);
    font-family: "Poppins400";
    font-size: 18px;
    font-style: normal;
    text-transform: capitalize;
    margin-top: 20px;
}
.category-content p {
    color: var(--main-color);
    font-family: "Poppins300";
    font-size: 13px;
    font-style: normal;
}
@media (max-width:576px) {
    .category-content p{
        margin-bottom: 15px !important;
    }
    
}

