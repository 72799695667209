.privacy-container {
    margin-top: 140px;
    padding-top: 40px;
    padding-bottom: 60px;
}

.privacy-title {
    color: #292929;
    font-family: "Poppins400";
    font-size: 34px;
    text-transform: capitalize;
}

.privacy-title-desc {
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 12px;
    letter-spacing: 0.84px;
}
.privacy-title-desc span {
    text-decoration: underline;
}

.privacy-para {
    color: #313131;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 13px;
    line-height: 27px; 
    letter-spacing: 0.6px;
}
.privacy-para.bold {
    font-family: "Poppins700";
}
.privacy-para span {
    font-family: "Poppins700";
}

.privacy-list {
    padding-left: 1rem !important;
    list-style: disc;
}

@media (max-width: 992px) {
    .privacy-container {
        margin-top: 99px;
    }
    .privacy-title-desc {
        font-size: 11px;
    }
    .privacy-title {
        font-size: 30px;
    }
    .privacy-para {
        font-size: 12px;
        line-height: 25px; 
    }
}

@media (max-width: 576px) {
    .privacy-title-desc {
        font-size: 10px;
    }
    .privacy-title {
        font-size: 28px;
    }
    .privacy-para {
        font-size: 11px;
        line-height: 22px; 
    }
}