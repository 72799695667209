.products-card {
  margin-top: 160px;
}

.product-card-content {
  position: relative;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  padding-right: 0 !important;
}

.product-card-text {
  padding: 15px 10px 15px 30px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  transition: .4s ease-in-out;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  cursor: pointer;
}
#secondary-color {
  background-color: var(--secondary-color);
  color: var(--main-color);
}
#main-color {
  background: var(--main-color);
  color: #fff;
}
.product-card-text:hover {
  left: 12px;
  position: absolute;
  width: calc(200% - 24px); 
  align-items: center;
  z-index: 10;
  opacity: 0.9;
}

.product-card-text:hover .product-para {
  text-align: center;
}


.product-card-text h1 {
  font-size: 28px;
  font-family: "Calibri";
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
  transition: .4s ease-in-out;
}
.product-card-text p {
  font-size: 16px;
  font-family: "Calibri";
  font-style: normal;
  font-weight: 400;
  margin: 10px 0 15px;
  width: 90%;
  transition: .4s ease-in-out;
}
.product-card-text a {
  font-size: 14px;
  font-weight: 700;
  transition: .4s ease-in-out;
}
.product-card-text i {
  font-size: 15px;
  margin: 5px 0 0 8px;
  transition: .4s ease-in-out;
}

.product-card-img {
  padding-left: 0 !important;
}

.product-card-img img {
  width: 100%;
  height: 100%;
}


@media (max-width: 1200px) {
  .product-card-text h1 {
    font-size: 20px;
}
.product-card-text p {
    font-size: 14px;
}
.product-card-text a {
    font-size: 12px;
}
.product-card-text i {
    margin: 4px 0 0 8px;
}
}
@media (max-width: 992px) {
  .product-card:not(:first-child) {
      margin-top: 50px;
  }
  .product-card-text h1 {
    font-size: 24px;
  }
  .product-card-text p {
      font-size: 15px;
  }
  .product-card-text a {
      font-size: 14px;
  }
}

@media (max-width: 768px) {
  .product-card-text h1 {
    font-size: 20px;
  }
  .product-card-text p {
    font-size: 13px;
  }
  .product-card-text a {
    font-size: 12px;
  }
  .products-card {
    margin-top: 60px;
  }
}

@media (max-width: 496px) {
  .product-card-text h1 {
      font-size: 20px;
  }
  .product-card-text p {
      font-size: 15px;
  }
  .product-card-text a {
      font-size: 12px;
  }
  .product-card-text i {
      font-size: 12px;
  }
  .card-responsive {
      display: flex !important;
      justify-content: center !important;
      align-items: center !important;
      flex-direction: column-reverse !important;
  }
  .product-card-content {
      padding-right: calc(var(--bs-gutter-x) * .5) !important;
      width: 200% !important;
  }
  .product-card-img {
      padding-left: calc(var(--bs-gutter-x) * .5) !important;
      width: 200% !important;
  }
  .product-card-text:hover {
      left: unset;
      position: unset;
      align-items: flex-start;
      width: 100%; 
      z-index: 10;
      opacity: 0.8;
  }
  .product-card-text:hover .product-para {
      text-align: unset;
  }
  

}

