.card-summary {
    border: 1px solid #D9D9D9;
    border-radius: 5px;
    padding:20px;
}
.billing-title {
    font-size: 16px;
    font-weight: 700;
    color: #000;
    margin-bottom: 20px;
}
.summary-price {
    float:right;
}
.card-summary-details {
    color:#4F4F4F;
    font-size: 15px;
    margin-bottom: 10px;
}
.order-comment-label {
    color: #4F4F4F;
    font-size: 13px;
    margin-top: 10px;
    margin-bottom:4px;
}
.order-coment {
    height: 60px;
    border: 1px solid #B2BCCA;
    border-radius: 4px;
    width: 100%;
}
.checkbox-label {
    font-size:13px;
    display: flex;
    gap: 5px;
    align-items: center;
    margin: 10px 0px;
}
.btn-ckeckout {
    background-color: #101728;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
    width: 100%;
    border: none;
    padding: 10px;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {
    .order-coment {
        height: 50px;
    }
    .checkbox-label {
        font-size: 11px;
    }
    .order-comment-label {
        margin-top: 0px;
    }
    .summary-price {
        font-size: 14px;
    }
    .card-summary {
        padding:10px;
    }
}


@media (max-width: 992px) {
    .card-summary {
        margin-top: 25px;
    }

}

@media (max-width: 692px) {}

@media (max-width: 567px) {}

@media (max-width: 492px) {}
