.card-product {
    width: 220px;
    padding: 15px 12px 20px;
    box-shadow: 0px 0px 35px 0px rgba(0, 0, 0, 0.05);
    border: none;
    outline: none;
    cursor: pointer;
  }
  .card-header {
    height: 35px;
    width: 100%;
    background: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: none;
    margin-bottom: 20px;
  }
  .card-header h2 {
    border-radius: 1px;
    background-color: #FD4B6B;
    padding: 4px 18px;
    color: #FFF;
    font-family: "Inter";
    font-size: 13px;
    font-style: normal;
    font-weight: 600;
    text-transform: capitalize;
  }
  .card-header img {
    cursor: pointer;
  }
  .card-img {
    width: 100%;
    height: 150px;
    border-bottom: 1px solid #EFEFEF;
    padding-bottom: 10px;
  }
  .card-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  .card-content {
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 7px;
  }
  .card-content h1 {
    margin: unset !important;
    font-family: "calibri";
    color: #909090;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    text-transform: capitalize;
  }
  .card-content p {
    margin: unset !important;
    padding: unset !important;
    color: #101728;
    font-family: "Inter400";
    font-size: 14px;
    line-height: 20px;
    text-transform: capitalize;
  }
  .card-content .price {
    color: var(--main-color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 5px; 
  }
  .card-content .price h3 {
    margin: unset !important;
    color: #101728;
    font-family: "Inter500";
    font-size: 13px;
    text-transform: capitalize;
  }
  .card-content .price span {
    color: #9E6B29;
  }

  @media (max-width: 1400px) {
    /* .card-content p {
      font-size: 22px;
    } */
  }


  @media (max-width: 1200px) {}
  
  
  @media (max-width: 992px) {
    .card-content p {
      font-size: 14px;
    }
  }
  
  @media (max-width: 692px) {}
  
  @media (max-width: 567px) {}
  
  @media (max-width: 492px) {}