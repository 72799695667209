.order-details-title {
    color: #000;
    font-family: "Inter700";
    font-size: 21px;
}

.order-details-content {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 10px;
}

.order-details-id {
    color: #000;
    font-family: "Inter400";
    font-size: 17px;
}
.order-details-id span {
    font-family: "Inter700";
    margin-right: 5px;
}

.order-details-status {
    display: flex;
    padding: 4px 15px !important;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
    font-size: 16px;
    font-family: "Inter400";
}
.order-details-status.delivered {
    color: #48BF53 !important;
    background: rgba(72, 191, 83, 0.20);
}
.order-details-status.pending {
    color: #EEA734 !important;
    background: rgba(238, 167, 52, 0.20);
}
.order-details-status.cancelled {
    color: red;
}

.order-details-close {
    margin-top: 20px;
    color:  #FFF;
    text-align: center;
    font-family: "Inter700";
    font-size: 14px;
    padding: 6px;
    width: 100px;
    border-radius: 5px;
    background: #101728;
    transition: .3s ease;
    cursor: pointer;
    border: none;
    outline: none;
}
.order-details-close:hover {
    opacity: 0.8;
}

.order-card-cotnainer-col {
    padding-left: unset !important;
}

.order-billing-container {
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    padding: 12px 20px;
}

.order-billing-title {
    color: #000;
    font-family: "Inter700";
    font-size: 14px;
}

.order-billing-content {
    margin-top: 20px;
}

.order-billing-tax {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
}

.order-billing-tax-title {
    color: #4F4F4F;
    font-family: "Quicksand400";
    font-size: 12px;
    font-style: normal;
}

.order-billing-tax-value {
    color:  #4F4F4F;
    font-family: "Quicksand600";
    font-size: 13px;
}

.order-billing-final-total {
    margin-top: 10px;
    padding-top: 10px;
    border-top: 1px solid #E0E0E0;
}

.order-billing-tax-title-total {
    color: #000;
    font-family: "Inter700";
    font-size: 14px;
}