.product-category-container {
  width: 100%;
}

.products-category {
  overflow: hidden;
  width: 100%;
  height: 130px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}


.products-category-title {
  margin-left: 20px;
  color: white;
  position: relative;
  font-size: 14px;
  font-family: "Inter300";
  letter-spacing: 1.44px;
  text-transform: uppercase;
  padding-right: 6px;
}

.products-category-title::before {
  position: absolute;
  content: '';
  width: 35px;
  height: 1px;
  left: 0;
  top: -4px;
  background-color: white;
}



.image-product-category {
  position: absolute;
  bottom: 0;
  width: 220px;
  right: -25%;
}
.category-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  
}
.category-icon-div {
  border-radius: 50%;
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.category-selected {
  border: 1px solid #CF9F47;
}
.icon-product-category {
  width:23px;
}
.product-category-name {
  font-family: Inter;
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.09em;
  color: #000;
  margin-top: 6px;
 text-align: center;
 line-height: 14.36px;
}
.hot-status {
  font-family: "Poppins700";
  background: #FD4B6B;
  color: #fff;
  font-size:10px;
  border-radius: 20px;
  padding: 0px 4px;
  font-weight: 700;
  position: absolute;
  top: 0px;
  right: 0px;
}
.trendy-status {
  font-family: "Poppins700";
  background: #FF512F;
  color: #fff;
  font-size:10px;
  border-radius: 20px;
  padding: 0px 4px;
  font-weight: 700;
  position: absolute;
  top: 0px;
  right: 0px;
}