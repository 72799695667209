.product-details {
    margin-top: 175px;
}
.path{
    margin-bottom: 20px;
    font-family: "Quicksand300";
    font-size: 16px;
}

.last {
    font-family: "Quicksand500";
}