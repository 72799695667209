.splitfour {
  margin-top: 180px;
}
.splitfour h1 {
  color: #292929;
  font-family: "Calibri Bold";
  font-size: 40px;
  font-style: normal;
}
.splitfour p {
  margin-top: 15px;
  color: #292929;
  font-family: "Calibri";
  font-size: 22px;
  font-style: normal;
}

@media (max-width: 576px) {
  .splitfour {
      margin-top: 60px;
      margin-bottom: unset;
  }
}
