
.categories-col-sm {
    padding:10px ;
}
.categories-col-sm .products-category {
    justify-content: space-between;
}


@media (max-width: 1400px) {}


@media (max-width: 1200px) {
    .categories-col-sm {
        padding: 7px;
    }
}


@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .categories-col-sm {
        padding:20px 0px;
    }
    .category-page-row {
        padding-top:unset;
    }
    .categories-col-sm .products-category {
        height: 250px;
}
}

@media (max-width: 567px) {

}

@media (max-width: 492px) {}