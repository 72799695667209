.product-reviews{
    /* margin-left: 150px;
    margin-right: 150px; */
    margin-bottom: 120px;
}
.comment{
    background-color: #F6F6F6;
    font-family: Inter;
    font-size: 15px;
    font-weight: 300;
    line-height: 35px;
    text-align: justified;
    color: #C6C6C6;
    margin-top: 30px;
} 
.form-control:focus {
    color: unset !important;
    background-color: unset !important;
    border-color: unset !important;
    outline: 0;
    box-shadow: unset  !important;
}
.comment-btn{
    margin-top: 20px;
    color: #FFF;
    font-size: 18px;
    padding: 10px 30px;
    background: var(--main-color);
    border-radius: 5px;
    font-family: Roboto;
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    letter-spacing: 0.25px;
    text-align: center;
    float: right;
}
.product-reviews .review-title {
    font-family: Inter;
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    color: #101728;
}

.reviews-container {
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 10px;
}

.add-comment {
    color: #FFF;
    text-align: center;
    font-family: "Roboto300" !important;
    font-size: 14px;
    letter-spacing: 0.35px;
}

@media (max-width: 1400px) {

}


@media (max-width: 1200px) {

}


@media (max-width: 992px) {

    .product-reviews{
        margin-left: 0px;
        margin-right: 0px;
    }

}

@media (max-width: 692px){

}  

@media (max-width: 567px) {

}

@media (max-width: 492px) {

}
