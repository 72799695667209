.title {
    margin-top: 80px !important;
}
.title h1 {
    position: relative;
    color: var(--main-color);
    font-size: 23px;
    font-style: normal;
    font-weight: 400;
    margin-bottom: 15px;
}
.title h1::before {
    position: absolute;
    content: '';
    width: 43px !important;
    height: 3px !important;
    left: 0 !important;
    top: -10px !important;
    background-color: var(--secondary-color);
}

.title p {
    color: var(--main-color);
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    text-transform: capitalize;
    margin-bottom: 10px;
}

@media (max-width: 576px) {
    .title p {
        font-size: 16px;
    }
    .title h1::before {
        width: 40px;
    }
    .title h1 {
        font-size: 21px;
    }
}
