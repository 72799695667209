.card-box {
  border: 1px solid #D9D9D9;
  border-radius: 5px;
  padding:30px;
  position: relative;
  margin-bottom: 25px;
}
.btn-div {
  position:absolute;
  top:0px;
  right:0px;
  display: flex;
}
.edit-div {
  background-color: #A5A5A5;  
  
}
.delete-div {
  background-color: #C72247;
  border-radius: 0px 4px 0px 0px;
    
}
.svg-margin {
  margin:10px
}
.item-title {
  color:#101728;
  font-size: 19px;
  font-weight: 500;
  text-transform: uppercase;
  margin-bottom: 10px;
}
.item-text {
  color: #939393;
  font-size:14px;
  font-weight: 300;
  margin-bottom: 10px;
}
.item-sub-title {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.item-attribute {
  font-weight:500;
  color: #1C1C1C;
  font-size:14px;
  margin-bottom: 10px;
}
.item-details-container {
font-size:14px;
color: #000;
margin-bottom: 10px;
}
.item-details-text {
  font-weight: 300;
  font-size:14px;
color: #000;
margin-bottom: 10px;
}
.img-fit {
  width: 100%;
  object-fit: cover;
}
.divider-cart {
  border: 1px solid #E1E1E1;
  margin-bottom: 10px;
  width: 50%;
}
.price {
  font-size: 16px;
  color:#101728;
  font-weight: 600;
  margin-bottom: 10px;
}
.card-box .quantity-text {
  font-size: 16px;
}
.card-box .input-group {
  padding-top: 10px;
  width: 100%;
}
input:focus-visible {
  outline: unset;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {

}

@media (max-width: 768px) {
  .card-box  .input-group {
    width: 40% !important;
  }
}

@media (max-width: 567px) {}

@media (max-width: 492px) {}
