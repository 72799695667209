.verify {
    margin-top: 120px;
    margin-bottom: -120px;
}
@media (max-width: 992px) {

    .verify {
        margin-top: 90px;
    }

}
@media (max-width: 567px) {
    .verify{
        margin-bottom: -60px;
    }
    
}