
:root {
  --main-color: #101728;
  --secondary-color: #ECC77C;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p {
  margin: 0 !important;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none !important;
}


/* Start Fonts */

@font-face {
  font-family: 'AnticDidone';
  src: url("../src/asset/fonts/AnticDidone-Regular.ttf");
}
@font-face {
  font-family: 'Calibri Bold';
  src: url("../src/asset/fonts/calibri-bold.ttf");
}
@font-face {
  font-family: 'Calibri light';
  src: url("../src/asset/fonts/calibri-light.ttf");
}
@font-face {
  font-family: 'Calibri';
  src: url("../src/asset/fonts/calibri-regular.ttf");
}
@font-face {
  font-family: 'Inter Thin';
  src: url("../src/asset/fonts/Inter-Thin.ttf");
}
@font-face {
  font-family: 'Inter';
  src: url("../src/asset/fonts/Inter-Light.ttf");
}
@font-face {
  font-family: 'ProtestStrike';
  src: url("../src/asset/fonts/ProtestStrike-Regular.ttf");
}




/* Poppins */
@font-face {
  font-family: "Poppins100";
  src: url(./asset/fonts/Poppins/Poppins-Thin.ttf);
}
@font-face {
  font-family: "PoppinsItalic100";
  src: url(./asset/fonts/Poppins/Poppins-ThinItalic.ttf);
}
@font-face {
  font-family: "Poppins200";
  src: url(./asset/fonts/Poppins/Poppins-ExtraLight.ttf);
}
@font-face {
  font-family: "PoppinsItalic200";
  src: url(./asset/fonts/Poppins/Poppins-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "Poppins300";
  src: url(./asset/fonts/Poppins/Poppins-Light.ttf);
}
@font-face {
  font-family: "PoppinsItalic300";
  src: url(./asset/fonts/Poppins/Poppins-LightItalic.ttf);
}
@font-face {
  font-family: "Poppins400";
  src: url(./asset/fonts/Poppins/Poppins-Regular.ttf);
}
@font-face {
  font-family: "PoppinsItalic400";
  src: url(./asset/fonts/Poppins/Poppins-Italic.ttf);
}
@font-face {
  font-family: "Poppins500";
  src: url(./asset/fonts/Poppins/Poppins-Medium.ttf);
}
@font-face {
  font-family: "PoppinsItalic500";
  src: url(./asset/fonts/Poppins/Poppins-MediumItalic.ttf);
}
@font-face {
  font-family: "Poppins600";
  src: url(./asset/fonts/Poppins/Poppins-SemiBold.ttf);
}
@font-face {
  font-family: "PoppinsItalic600";
  src: url(./asset/fonts/Poppins/Poppins-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Poppins700";
  src: url(./asset/fonts/Poppins/Poppins-Bold.ttf);
}
@font-face {
  font-family: "PoppinsItalic700";
  src: url(./asset/fonts/Poppins/Poppins-BoldItalic.ttf);
}
@font-face {
  font-family: "Poppins800";
  src: url(./asset/fonts/Poppins/Poppins-ExtraBold.ttf);
}
@font-face {
  font-family: "PoppinsItalic800";
  src: url(./asset/fonts/Poppins/Poppins-ExtraBoldItalic.ttf);
}

/* Roboto */
@font-face {
  font-family: "Roboto100";
  src: url(./asset/fonts/Roboto/Roboto-Thin.ttf);
}
@font-face {
  font-family: "RobotoItalic100";
  src: url(./asset/fonts/Roboto/Roboto-ThinItalic.ttf);
}
@font-face {
  font-family: "Roboto300";
  src: url(./asset/fonts/Roboto/Roboto-Light.ttf);
}
@font-face {
  font-family: "RobotoItalic300";
  src: url(./asset/fonts/Roboto/Roboto-LightItalic.ttf);
}
@font-face {
  font-family: "Roboto400";
  src: url(./asset/fonts/Roboto/Roboto-Regular.ttf)
}
@font-face {
  font-family: "RobotoItalic400";
  src: url(./asset/fonts/Roboto/Roboto-Italic.ttf);
}
@font-face {
  font-family: "Roboto500";
  src: url(./asset/fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
  font-family: "RobotoItalic500";
  src: url(./asset/fonts/Roboto/Roboto-MediumItalic.ttf);
}
@font-face {
  font-family: "Roboto700";
  src: url(./asset/fonts/Roboto/Roboto-Bold.ttf);
}
@font-face {
  font-family: "RobotoItalic700";
  src: url(./asset/fonts/Roboto/Roboto-BoldItalic.ttf);
}
@font-face {
  font-family: "Roboto900";
  src: url(./asset/fonts/Roboto/Roboto-Black.ttf);
}
@font-face {
  font-family: "RobotoItalic900";
  src: url(./asset/fonts/Roboto/Roboto-BlackItalic.ttf);
}
/* End Roboto */

/* Inter */
@font-face {
  font-family: "Inter100";
  src: url(./asset/fonts/Inter/Inter-Thin.ttf);
}
@font-face {
  font-family: "Inter200";
  src: url(./asset/fonts/Inter/Inter-ExtraLight.ttf);
}
@font-face {
  font-family: "Inter300";
  src: url(./asset/fonts/Inter/Inter-Light.ttf);
}
@font-face {
  font-family: "Inter400";
  src: url(./asset/fonts/Inter/Inter-Regular.ttf);
}
@font-face {
  font-family: "Inter500";
  src: url(./asset/fonts/Inter/Inter-Medium.ttf);
}
@font-face {
  font-family: "Inter600";
  src: url(./asset/fonts/Inter/Inter-SemiBold.ttf);
}
@font-face {
  font-family: "Inter700";
  src: url(./asset/fonts/Inter/Inter-Bold.ttf);
}
@font-face {
  font-family: "Inter800";
  src: url(./asset/fonts/Inter/Inter-ExtraBold.ttf);
}
@font-face {
  font-family: "Inter900";
  src: url(./asset/fonts/Inter/Inter-Black.ttf);
}
/* End Inter */


/* Quicksand */

@font-face {
  font-family: "Quicksand300";
  src: url(./asset/fonts/Quicksand/Quicksand-Light.ttf);
}

@font-face {
  font-family: "Quicksand400";
  src: url(./asset/fonts/Quicksand/Quicksand-Regular.ttf);
}

@font-face {
  font-family: "Quicksand500";
  src: url(./asset/fonts/Quicksand/Quicksand-Medium.ttf);
}

@font-face {
  font-family: "Quicksand600";
  src: url(./asset/fonts/Quicksand/Quicksand-SemiBold.ttf);
}

@font-face {
  font-family: "Quicksand700";
  src: url(./asset/fonts/Quicksand/Quicksand-Bold.ttf);
}

/* End Quicksand */


/* Dm Sans */
@font-face {
  font-family: "Dmsans100";
  src: url(./asset/fonts/DmSans/DMSans-Thin.ttf);
}
@font-face {
  font-family: "DmsansItalic100";
  src: url(./asset/fonts/DmSans/DMSans-ThinItalic.ttf);
}
@font-face {
  font-family: "Dmsans200";
  src: url(./asset/fonts/DmSans/DMSans-ExtraLight.ttf);
}
@font-face {
  font-family: "DmsansItalic200";
  src: url(./asset/fonts/DmSans/DMSans-ExtraLightItalic.ttf);
}
@font-face {
  font-family: "Dmsans300";
  src: url(./asset/fonts/DmSans/DMSans-Light.ttf);
}
@font-face {
  font-family: "DmsansItalic300";
  src: url(./asset/fonts/DmSans/DMSans-LightItalic.ttf);
}
@font-face {
  font-family: "Dmsans400";
  src: url(./asset/fonts/DmSans/DMSans-Regular.ttf);
}
@font-face {
  font-family: "DmsansItalic400";
  src: url(./asset/fonts/DmSans/DMSans-Italic.ttf);
}
@font-face {
  font-family: "Dmsans500";
  src: url(./asset/fonts/DmSans/DMSans-Medium.ttf);
}
@font-face {
  font-family: "DmsansItalic500";
  src: url(./asset/fonts/DmSans/DMSans-MediumItalic.ttf);
}
@font-face {
  font-family: "Dmsans600";
  src: url(./asset/fonts/DmSans/DMSans-SemiBold.ttf);
}
@font-face {
  font-family: "DmsansItalic600";
  src: url(./asset/fonts/DmSans/DMSans-SemiBoldItalic.ttf);
}
@font-face {
  font-family: "Dmsans700";
  src: url(./asset/fonts/DmSans/DMSans-Bold.ttf);
}
@font-face {
  font-family: "DmsansItalic700";
  src: url(./asset/fonts/DmSans/DMSans-BoldItalic.ttf);
}
@font-face {
  font-family: "Dmsans800";
  src: url(./asset/fonts/DmSans/DMSans-ExtraBold.ttf);
}
@font-face {
  font-family: "DmsansItalic800";
  src: url(./asset/fonts/DmSans/DMSans-ExtraBoldItalic.ttf);
}
/* End Dm Sans */



/* End Fonts */

/* customize bootstrab container */
/* @media (min-width: 576px) {
  .container {
    max-width: 560px !important; 
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 760px !important; 
  }
}

@media (min-width: 992px) {
  .container {
    max-width: 980px !important; 
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1200px !important; 
  }
}

@media (min-width: 1400px) {
  .container {
    max-width: 1400px !important; 
  }
}

@media (min-width: 1500px) {
  .container {
    max-width: 1500px !important; 
  }
}

@media (min-width: 1600px) {
  .container {
    max-width: 1600px !important; 
  }
}

@media (min-width: 1700px) {
  .container {
    max-width: 1700px !important; 
  }
} */


/* Global Classes */
.margin-top {
  margin-top: 180px;
}

