.category-filter {
    display: flex;
    margin-bottom: 10px;
    /* align-content: center; */
    align-items: center;
}

.category-name {
    font-family: Inter;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.09em;
    color: #303030;
}

.subcategory-list {
    display: flex;
    flex-direction: column;
    padding-left: 35px;

}

.arrow {
    transition: transform 0.3s;
    padding-right: 5px;
    padding-bottom: 3px;
}

.category-name.selected-cat {
    color: #CF9F47 !important;
    border: none;
}

.check-category {
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.09em;
    color: #303030;
    margin-bottom: 10px;
}


input[type=checkbox] {
  accent-color: white;
  border: 1px solid #D2D2D2;
  /* border-radius: 8px; */
}



.custom-checkbox {
  width: 18px;
  height: 18px;
  /* background-color: white; */
  /* border-radius: 5%; */
  vertical-align: middle;
  /* border: 2px solid #ddd; */
  appearance: none;
  /* -webkit-appearance: none; */
  outline: none;
  cursor: pointer;
}




.custom-checkbox:checked:before {
  /* background: #ffffff !important; */
  /* color: #212021 !important; */
  content: "\2713" !important;
  text-align: center;
  width: 17px;
  height: 15px;
  display: flex;
  font-weight: bolder;
  font-size: 14px;
  align-items: center;
  justify-content: center;
  vertical-align: middle;
}
