.home {
    /* margin-top: 120px; */
}
/* WhatsApp Floating Button */
.whatsapp-float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 30px;
    right: 30px;
    background-color: #25d366;
    color: #fff;
    border-radius: 50px;
    text-align: center;
    font-size: 30px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.3);
    z-index: 1000;
}

.whatsapp-float .whatsapp-icon {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    padding: 10px;
}

.whatsapp-float:hover {
    transform: scale(1.1);
    transition: transform 0.2s ease-in-out;
}