.page-item .page-link {
    margin-left: 6px !important;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 37px;
    height: 37px;
    border-radius: 4px !important;
    margin: 0 5px;
    border: 1px solid #A4A4A4;
    cursor: pointer;
    color:#A4A4A4;
    font-family: "Inter600";
    font-size: 14px;
}

.active>.page-link, .page-link.active {
    background-color: #ECC77C30 !important;
    color: #101728 !important;
    border: 1px solid #ECC77C !important;
}

.page-item.disabled .page-link {
    border: 1px solid #BEBEBE !important;
    opacity: 0.3 !important;
    background: #fff !important;
}