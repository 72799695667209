.ver-content{
  padding-top: 80px;
  padding-bottom: 80px;
}


.check-div{
  width: 50px;
  border-radius: 5px;
  background: #61AF6430;
  text-align: center;
  padding: 10px;
  scale: 0.8;
}


.ver-content .title{
  padding-top: 5px;
}
.ver-content .subtitle {
  padding-top: 5px;
  line-height: 15px;

}

.margin-check-button{
  margin-top: 5px !important;
}



@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {

}

@media (max-width: 692px) {}

@media (max-width: 567px) {}

@media (max-width: 492px) {}