.image-product{
  box-shadow: 0px 0px 40px 0px #00000017;
  background-color: #FFFFFF;
  height: 100%;
}
.image-product .splide{
  height: 100%;

}

.image-product .splide__track {
  height: 100%;

}

.splide__pagination__page.is-active {
  background: #101728 !important;
  transform: unset !important;
  z-index: 1;
}

.image-product .splide__pagination__page {
  background: rgba(16, 23, 40, 0.38);
  height: 3px  !important;
  width: 38px !important;
  border-radius: 0;
  margin-bottom: 25px;
  margin-right: 10px;
  margin-left: 10px;

}
.splide__arrow {
  border-radius: 20% !important;
  background: #F2F2F29C !important;
}

.image-container{
  display: flex;
  justify-content: center;
  align-items: center;
}

.second-show-swipper .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.first-show-swipper .swiper-slide img {
  display: block;
  width: 75%;
  height: 100%;
  object-fit: contain;
}
.swiper-button-prev, .swiper-button-next{
  border-radius: 20% !important;
  background: #F2F2F29C !important;
  width: 30px !important;
  height: 30px !important;
}
.swiper-button-prev:after, .swiper-button-next:after {
  font-family: swiper-icons;
  font-size: unset !important;
  text-transform: none !important;
  letter-spacing: 0;
  font-variant: initial;
  line-height: 1;
  color: black;
}
/* .swiper {
  width: 100%;
  height: 100%;
} */


.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

/* .swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.second-show-swipper{
  margin-left: 20px;
}
.second-show-swipper .swiper {
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

.swiper-pagination {
  margin-bottom: 25px;
}

/* Custom active pagination bullet styles */
.swiper-pagination-bullet-active {
  background: #101728 !important;
  transform: unset !important;
  z-index: 1;
}

/* Custom pagination bullet styles */
.swiper-pagination-bullet {
  background: rgba(16, 23, 40, 0.38);
  height: 3px !important;
  width: 38px !important;
  border-radius: 0;
  margin-bottom: 25px;
  margin-right: 10px;
  margin-left: 10px;
}



.swiper-slide {
  background-size: cover;
  background-position: center;
}

.first-swapper {
  height: 100%;
  width: 75%;
  margin-left: 20px;
}

.selected-image {
  border: 2px solid var(--secondary-color);
}

.other-image {
  opacity: 0.5;
}

@media (max-width: 992px) {
  .first-swapper {
    margin-left: 0px;
  }
}