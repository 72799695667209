
.tab-photo {
    color: #181D27;
    font-family: "Poppins400";
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 19px;
    padding-bottom: 20px;
}

.profile-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-photo-div-parent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 20px;
    flex-wrap: wrap;
    border-bottom: 2px solid rgba(232, 234, 238, 0.40);
    padding-bottom: 30px;
}

.profile-photo-div .custom-file-upload {
    border: unset;
    border-radius: 50%;
    width: 85px;
    height: 85px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.imageChange {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.profile-photo-div .camera-profile {
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--main-color);
    border-radius: 50%;
    position: absolute;
    left: 62px;
    bottom: 0;
    width: 30px;
    height: 30px;
}
.camera-profile img {
    width: 14px;
    height: 14px;
}

.profile-photo-div input[type="file"] {
    display: none;
}

.profile-name {
    color: #000;
    font-family: "Inter700";
    font-size: 20px;
    margin-bottom: 0px !important;
    text-transform: capitalize;
}

.profile-email {
    color: #000;
    font-family: "Inter400";
    font-size: 16px;
    line-height: 18px;
}

.profile-initial{
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--main-color);
    background: rgba(128, 128, 128, 0.05);
    width: 100%;
    height: 100%;
    font-size: 32px;
    font-family: "Poppins400";
}

.profile-text-info {
    display: flex;
    flex-direction: column;
    gap: 3px;
}

.profile-photo-div {
    position: relative;
}

@media (max-width: 992px) {
    .profile-info-container {
        
    }
}