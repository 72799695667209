.checkout-page {
    margin-top: 190px; 
}

.checkout-box {
    border: 1px solid #D9D9D9;
    padding: 20px;
    border-radius: 5px;
    margin-bottom: 20px;
}
.payment-box {
    border: 1px solid #D9D9D9;
    padding: 20px;
    border-radius: 5px;
}
.checkout-title-div {
    display: flex;
    align-items: center;
    gap: 10px;
}
.address-div {
    padding-left: 32px;
}

.circle {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #101728;
    display: flex;
    justify-content: center;
    font-size: 12px;
    font-weight: 500;
}
.circle-disabled {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 1px solid #D9D9D9;
  color:#D9D9D9;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
}

.checkout-title {
    font-family: Inter;
    color: #101728;
    font-size: 25px;
    font-weight: 600;
}
.checkout-title-disabled{
  font-family: Inter;
  color: #D9D9D9;
  font-size: 25px;
  font-weight: 600;
}

.address-title {
    font-family: Inter;
    color: #101728;
    font-size: 18px;
    font-weight: 500;
}

.address-name {
    font-family: Inter;
    color: #1C1C1C;
    font-size: 14px;
    font-weight: 700;
}

.address-text {
    font-family: Inter;
    color: #1C1C1C;
    font-size: 14px;
    font-weight: 400;
}

.address-edit {
    font-family: Inter;
    color: #1C1C1C;
    font-size: 14px;
    font-weight: 400;
    text-decoration: underline;
}
.label-radio {
    display: flex;
    gap:8px;
    margin-bottom: 7px;
}
.add-address-btn {
    text-decoration: underline !important;
    color: #000 !important;
    padding: unset !important;
    text-transform: unset !important;
    font-size: 15px !important;
    font-family: 'Inter' !important;
}
.btn-selected-address {
    font-family: Inter !important;
    background-color: #101728 !important;
    border: none !important;
    border-radius: 3px !important;
    color: #fff !important;
    font-size: 13px !important;
    padding: 7px 25px !important;
    font-weight: 600 !important;
    margin-top: 30px !important;
}
.address-input {
    border: 1px solid #D1D1D1;
    border-radius: 5px;
    padding: 7px 10px;
    width: 100%;
    font-family: Inter;
    font-size: 14px;
    /* color:#D1D1D1; */
}
.address-form-text {
    color: #101728;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 0.25px !important;
    text-align: left;
    margin-top: 15px;
}
.dialog-content-text{
  font-family: Inter !important;
  font-size: 18px !important;
  font-weight: 800 !important;
  text-align: left;
  color: #101728 !important;
}



.label-radio {
  accent-color: #101728;
}

  
  .flag-container {
    margin-top: 6px;
    margin-bottom: 6px;
  }
  
  .intl-tel-input {
    width: 100%;
  }
  
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    background-color: unset !important;
    display: flex !important;
    border-right: 1px solid #E5E5E5 !important;
  
  }
  
  .intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    font-size: 12px !important;
    padding-left: 5px !important;
  
  }
  
  .intl-tel-input.allow-dropdown input,
  .intl-tel-input.allow-dropdown input[type=tel] {
    color: #000000 !important;
    font-family: Inter !important;
    font-size: 12px !important;
    line-height: 28px !important;
  }
  .react-tel-input .selected-flag {
    width:44px;
  }
  .react-tel-input .form-control {
    width: 100%;
  }
  .payment-text {
    color: #1C1C1C;
    font-family: Inter;
    font-size: 14px;
    font-weight:400;
    margin-top: 10px;

  }
  .btn-payment {
    border-radius: 3px !important;
    border:2px solid #101728 !important;
    color:#101728 !important;
    padding: 5px 20px !important;
    font-family: Inter;
    width: 30%;
    font-size: 14px;

  }
  .payment-div {
    display: flex;
    gap:20px;
  }
  .payment-div-disable {
    display: none;
  }

@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {


}

@media (max-width: 768px) {
  .checkout-box {
    padding: 10px 5px;

  }
  .address-text {
    font-size: 12px;

  }
  .address-name {
    font-size: 12px;

  }
  .address-edit {
font-size: 12px;
  }
  .address-div {
    padding-left: 10px;
}
}

@media (max-width: 567px) {}

@media (max-width: 492px) {}

