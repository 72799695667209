
.check-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 80px;
  padding-bottom: 80px;
}



.check-content .check-title{
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding-top: 20px;
  color: #101728;

}
.check-content .subtitle {
  font-family: Inter;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #777777;
  padding-top: 8px;

}
.check-content .text{
  font-family: Inter;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 0.3px;
  color: #1A1A1A;
  padding-top: 15px;
}




@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {

  .check-content .check-title {
    font-size: 25px;
  }
  .check-content .subtitle {
    font-size: 12px;
  }
  .check-content .text{
    font-size: 10px;
    padding-top: 5px;
  }
}

@media (max-width: 692px) {}

@media (max-width: 567px) {}

@media (max-width: 492px) {}