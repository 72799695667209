.edit-profile-container {
    padding-top: 20px;
}

.edit-profile-title {
    color: #101728;
    font-family: "Inter500";
    font-size: 21px;
}

.sub-edit-profile-title {
    margin: 25px 0;
    color: #3C3C3C;
    font-family: "Inter700";
    font-size: 15px;
}

.security-information {
    border-top: 2px solid rgba(232, 234, 238, 0.40);
    margin-top: 35px;
}

.profile-btn-container {
    margin-top: 20px;
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 10px;
}
.profile-btn {
    text-align: center;
    font-family: "Inter600";
    font-size: 13px;
    border-radius: 5px;
    border: 1px solid var(--main-color);
    padding: 6px;
    width: 100px;
}
.profile-btn:first-child {
    color: var(--main-color);
    background: transparent;
}
.profile-btn:last-child {
    color: #fff;
    background: var(--main-color);
}


.reset-profile-btn {
    font-family: "Inter600";
    font-size: 13px;
    border-radius: 5px;
    border: 1px solid var(--main-color);
    padding: 8px 18px;
    text-decoration: none;
    color: var(--main-color);
}

@media (max-width: 992px) {

}

@media (max-width: 768px) {
    .profile-inputs-container {
        padding: unset !important;
        margin-bottom: 15px;
    }
}

@media (max-width: 576px) {
  
}