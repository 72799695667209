.order-history-title {
    color: #000;
    font-family: "Inter700";
    font-size: 21px;
}

.order-history-para {
    color: #000;
    font-family: "Inter400";
    font-size: 17px;
}

.order-table {
    margin-top: 20px;
}

.order-table-title {
    color: #747474 !important;
    font-family: "Inter700" !important;
    font-size: 14px !important;
}

.order-table-desc {
    color: #747474 !important;
    font-family: "Inter400" !important;
    font-size: 14px !important;
}

.order-table th, .order-table td {
    padding-left: unset !important;
    border-bottom: 1px solid #F4F4F4 !important;
}

.order-status {
    display: flex;
    padding: 5px !important;
    justify-content: center;
    align-items: center;
    border-radius: 22px;
}
.order-status.delivered {
    color: #48BF53 !important;
    background: rgba(72, 191, 83, 0.20);
}

.order-status.pending {
    color: #EEA734 !important;
    background: rgba(238, 167, 52, 0.20);
}

.order-status.cancelled {
    color: red;
}

.order-details-btn {
    color: #747474;
    font-family: "Inter300";
    font-size: 15px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    background: transparent;
    border: none;
    outline: none;
}

.order-filter-dropdown {
    cursor: pointer;
    border: unset !important;
    color: #747474 !important;
    font-family: "Inter700" !important;
    font-size: 14px !important;
    outline: unset !important;
}


.order-filter-dropdown:focus {
    border: unset !important;
    outline: unset !important;
    border-radius: 10px !important;
}

.order-filter-option {
    color: #000 !important;
    font-family: "Inter400" !important;
    font-size: 14px !important;
}

.order-filter-option  {
    border: unset !important;
}