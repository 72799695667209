.shopping-page {
     margin-top: 190px; 
}
.shopping-cart-row {
     padding-top:15px;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {
     .shopping-cart-row {
          padding-top:35px;
     }

}

@media (max-width: 692px) {}

@media (max-width: 567px) {}

@media (max-width: 492px) {}