.reset {
    margin-top: 120px;
    margin-bottom: -120px;
}

.ver-content {
    padding-top: 80px;
    padding-bottom: 80px;
}

.ver-content .title {
    padding-top: 5px;
}

.reset-password-button {
    width: 270px;
    margin-top: 20px;
    border-radius: 5px;
    background: rgba(16, 23, 40, 1);
    color: white;
    padding: 7px 20px;
    border: none;
    cursor: pointer;
    font-size: 17px;
}

.reset-input {
    width: 270px !important;
    background: #FFFFFF !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 5px !important;
    padding: 5px 38px !important;
    font-style: normal !important;
    color: #000000;
    font-family: Inter;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0.25px !important;

}

.error-message {
    color: red;
    font-size: 10px;
    margin-left: -157px;
}

.error-message1 {
    color: red;
    font-size: 10px;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {

    .reset {
        margin-top: 90px;
    }

    .reset-password-button {
        font-size: 14px;
    }

    .reset-input {
        font-size: 12px !important;
    }
}

@media (max-width: 692px) {}

@media (max-width: 567px) {
    .reset {
        margin-bottom: -60px;
    }
}

@media (max-width: 492px) {}