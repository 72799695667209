.order-card-container {
    margin-top: 20px;
    border-radius: 5px;
    border: 1px solid #D9D9D9;
    padding: 12px 20px;
}

.order-card-title-container {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 35px;
}

.order-card-title {
    color: #101728;
    font-family: "Inter500";
    font-size: 19px;
    text-transform: uppercase;
}

.order-card-desc {
    margin-top: 5px;
    color: #1C1C1C;
    text-align: justify;
    font-family: "Inter300";
    font-size: 13px;
    line-height: 20px;
    text-transform: capitalize;
}

.order-card-price {
    color: #101728;
    font-family: "Inter400";
    font-size: 22px; 
}
.order-card-price span {
    font-family: "Inter700";
    font-size: 14px;
}

.order-card-price-desc {
    margin-top: 5px;
    color: #939393;
    font-family: "Inter400";
    font-size: 10px;
    line-height: 16px; 
}

.order-card-brand {
    margin-top: 10px;
    color: #1C1C1C;
    text-align: justify;
    font-family: "Inter500";
    font-size: 13px;
    text-transform: capitalize;
}
.order-card-brand span {
    margin-left: 3px;
    color: #1C1C1C;
    font-family: "Quicksand300";
    font-size: 13px;
    text-transform: capitalize;
}

.order-card-title-details {
    margin-top: 15px;
    color: #101728;
    font-family: "Inter500";
    font-size: 14px;
    text-transform: capitalize;
}

.order-card-details-container {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid #E1E1E1;
    max-width: 200px;
}


.order-card-detail-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order-card-detail-container:not(:first-child) {
    margin-top: 10px;
}


.order-card-spec {
    color: #000;
    font-family: "Quicksand500";
    font-size: 13px;
}

.order-card-detail {
    color: #000;
    font-family: "Quicksand400";
    font-size: 13px;
}