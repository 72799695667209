._2hRXr:after, ._2hRXr:before {
    background-color: var(--main-color) !important;
}

._qWTcH {
    color: var(--secondary-color) !important;
}

._2aOn7 {
    color: var(--main-color) !important;
}



@media (max-width: 1200px) {
    ._2EBbg {
        width: 34px !important;
        height: 60px !important;
    }
    ._qWTcH {
        font-size: 35px !important;
    }
}

@media (max-width: 992px) {
    ._2EBbg {
        width: 32px !important;
    }
    ._qWTcH {
        font-size: 33px !important;
    }
    ._2hRXr {
        margin-left: 3px !important;
        margin-right: 3px !important;
    }
    ._2aOn7 {
        font-size: 13px !important;
    }
}