.verification-container {}

.back-img {
  background-image: url('../../../../asset/images/Sign/verify-backg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: left;
} 

.ver-content {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  padding-top: 60px;
  padding-bottom: 60px;
}

.ver-content .ver-title {
  font-size: 30px;
  font-weight: bolder;
  letter-spacing: 0px;
  text-transform: uppercase;
  padding-top: 20px;
  color: #101728;
}

.ver-content .ver-subtitle {
  font-family: Inter;
  font-size: 15px;
  font-weight: 300;
  line-height: 20px;
  letter-spacing: 0.25px;
  text-align: center;
  color: #777777;
  padding-top: 8px;
}

.email-div {
  width: 50px;
  border-radius: 5px;
  background: #ECC77C30;
  text-align: center;
  padding: 10px;
  scale: 0.8;
}



.vi__character {
  height: 100%;
  flex-grow: 1;
  flex-basis: 0;
  text-align: center;
  font-size: 20px;
  color: black;
  background-color: white;
  border: 2px solid black;
  cursor: default;
  user-select: none;
  box-sizing: border-box;
  border-radius: 5px;
  line-height: 26px;
  margin-top: 15px;
}



.vi__container {
  position: relative;
  display: flex;
  gap: 5px;
  height: 31px;
  width: 200px;
}

.vi__character--inactive:first-child {

  color: transparent;
  background-color: white;
}

.vi__character--selected {
  border: 1px solid var(--main-color);
  background-color: white !important;
  outline: 2px solid var(--main-color);
}

.ver-button {
  width: 280px;
  margin-top: 30px !important;
  border-radius: 5px;
  background: rgba(16, 23, 40, 1);
  color: white;
  padding: 7px 20px;
  border: none;
  cursor: pointer;
  font-size: 17px;
}

.ver-text {
  font-family: Inter;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.30px;
  margin-top: 15px;
  color: #1A1A1A;
}

.ver-text span {
  font-weight: 900;
}



@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {
  .ver-content .ver-title {
    font-size: 25px;
  }
  .ver-content .ver-subtitle {
    font-size: 12px;
  }
  .vi__character {
    font-size: 15px;
  }
  .ver-button {
    font-size: 14px;
  }
  .ver-text {
    font-size: 10px;
  }
  
}

@media (max-width: 692px) {}

@media (max-width: 567px) {}

@media (max-width: 492px) {}