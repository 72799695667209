  
.portal-container {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  
  .portal-container .left {
    background-image: url("../../asset/images/portal/portal-left.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    left: 0;
    top: 0;
    width: 55%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 1s ease;
  }
  .portal-container .left:hover  {
    width: 65%;
  }

  .portal-container .right {
    background-image: url("../../asset/images/portal/portal-right.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    position: absolute;
    width: 55%;
    right: 0;
    height: 100%;
    top: 0;
    clip-path: polygon(108px 0, 100% 0, 100% 100%, 0px 100%);
    z-index: 1111111;
    transition: 1s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .portal-container .right::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 200px;
    background: #fff; 
    clip-path: polygon(111px -33px, 129px -64px, 11px 100%, 0 100%);
    z-index: 11111111111111;
}
  .portal-container .right.expanded {
    width: 45%; /* Adjust as needed */
  }
  
  .portal-container .right:hover {
    width: 65%;
  }
  .portal-container .right:hover + .left {
    width: 45% !important;
}
 
.portal-content {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-bottom: 180px;
  max-width: 400px;
}
 
.portal-para {
  color: #494949;
  font-family: "Inter300";
  font-size: 16px;
}

.portal-title {
  color: #494949;
  font-family: "Inter400";
  margin: 20px 0 15px;
  font-size: 30px;
  text-transform: uppercase;
}
.portal-title span {
  font-family: "Inter500";
}

.portal-desc {
  color: #494949;
  text-align: center;
  font-family: "Inter300";
  font-size: 14px;
  line-height: 23px; /* 181.25% */
}

.portal-link {
  cursor: pointer;
  display: block;
  margin-top: 20px;
  padding: 6px 24px;
  border: 1px solid #494949;
  text-decoration: none;
  color: #494949;
  font-family: "Inter300";
  font-size: 14px;
  font-style: normal;
  text-transform: capitalize;
}

.portal-logo-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.logo-m {
  width: 140px;
  height: 50px;
}
.logo-g {
  width: 80px;
}

.portal-overlay {
  display: none;
}


@media(max-width: 1200px) {
  .portal-link {
    font-size: 13px;
  }
  .portal-desc {
    font-size: 13px;
    line-height: 21px;
  }
  .portal-title {
    font-size: 26px;
  }
  .portal-para {
    font-size: 15px;
  }
  .portal-content {
    max-width: 330px;
  }
}
@media(max-width: 1100px) {
  .portal-container .right:hover + .left {
    width: 46% !important;
  }
  .portal-container .left {
    width: 56%;
  }
  .portal-container .left:hover  {
    width: 66%;
  }
  .portal-container .right:hover {
    width: 66%;
  }
}
@media (max-width: 970px) {
  .portal-container .right::before {
    display: none;
  } 
  .portal-container .left, .portal-container .right {
    width: 100%;
    clip-path: unset;
    height: 50%;
    position: relative;
    z-index: 111;
  }
  .portal-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .portal-content {
    margin-bottom: unset !important;
  }
  .portal-container .left:hover  {
    width: 100%;
  }
  .portal-container .right.expanded {
    width: 100%;  
  }
  .portal-container .right:hover {
    width: 100%;
  }
  .portal-container .right:hover + .left {
    width: 100% !important;  
  }
  .portal-container .right {
    background-position-y: top;
  }
} 
@media(max-width: 768px) {
  .portal-container .left {
    border-top: 3px solid #fff;
  }
  .portal-overlay {
    display: block;
    background: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
  .portal-desc {
    color: #fff;
    font-family: "Inter200";
  }
  .portal-link{
    color: #fff;
    border: 1px solid #fff;
  }
  .portal-para {
    color: #fff;
    font-family: "Inter200";
  }
  .portal-title {
    color: #fff;
    font-family: "Inter300";
  }
  .portal-title span {
    font-family: "Inter400";
  }
}