.App {
  max-width: 100%;
  overflow: hidden;
}

.genuis-loading {
  position: relative;
  height: 100vh;
  width: 100%;
  /* background: #000; */
  background:white;
}
.loading-letters {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  
}

.loading-letters span {
  font-size: 2vw;
  letter-spacing: 0.2rem;
  color: #faebd7;
  text-shadow: 0 0 0 #faebd7;
  /* animation: loading 0.5s ease-in-out infinite alternate; */
}

@keyframes loading {
  to {
      text-shadow: 20px 0 70px var(--main-color);
      color: var(--main-color);
  }
}
.loading-letters span:nth-child(2) {
  animation-delay: 0.1s;
}
.loading-letters span:nth-child(3) {
  animation-delay: 0.2s;
}
.loading-letters span:nth-child(4) {
  animation-delay: 0.3s;
}
.loading-letters span:nth-child(5) {
  animation-delay: 0.4s;
}
.loading-letters span:nth-child(6) {
  animation-delay: 0.5s;
}
.loading-letters span:nth-child(7) {
  animation-delay: 0.6s;
}
.loading-letters span:nth-child(8) {
  animation-delay: 0.7s;
}