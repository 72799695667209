.hero {
    height: 800px;
    margin-bottom: -140px;
 
}

.hero-container {

    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center;
    width: 100%;
     height: 100%; 
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
}

.splide__slide {
    display: flex;
    justify-content: center;
    align-items: center;
}
.hero-title {
    color: #fff;
    font-size:40px;
    font-weight: 500 !important;

}
.hero-sub-title {
    color:#fff;
    font-weight: 700;
    margin-bottom: 18px;
    font-size: 28px;
}
.hero-btn {
    background-color: #fff;
    border: none;
    padding:10px 20px;
    color: #EE3A59;
    font-size: 15px;
    border-radius: 5px;
    font-weight: 700;
    width: 10%;
    text-transform: uppercase;


}
#hero-bottom {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 40%;
}
#hero-center {
    position: absolute;
    left: 40%;
    bottom: 0;
}
#hero-left {
    position: absolute;
    left: -20px;
    top: -200px;
    z-index: -1;
}

.hero .splide {
    height: 100% !important;
}
.hero .splide__track {
    height: 100% !important;
}

/* .hero-container {
    position: relative;
} */

.hero-content {
    margin-top: 120px;
    max-width: 78%;
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
}

.hero-content-title {
    color: var(--main-color);
    font-family: "Calibri";
    font-size: 46px;
    font-style: normal;
    text-transform: uppercase;
}
.hero-content-title span {
    font-family: "Calibri Bold";
}

.hero-content-para {
    color: #101728;
    font-family: "Calibri";
    font-size: 18px;
    font-style: normal;
    text-transform: capitalize;
    padding-bottom: 20px;
}

.hero-content-link {
    margin-top: 20px;
    display: block;
    color: #FFF;
    font-family: "Calibri Bold";
    font-size: 18px;
    text-transform: uppercase;
    padding: 15px;
    width: 60% !important;
    text-align: center;
    background: var(--main-color);
    border-radius: 5px;
}

.hero-sale {
    position: absolute;
    left: 30px;
    bottom: 10px;
    z-index: 1;
}

.hero-sale-title {
    color: var(--main-color);
    font-family: "Calibri";
    font-size: 42px;
    text-transform: uppercase;
}
.hero-sale-title span {
    font-family: "Calibri Bold";
}

.hero-sale-para {
    max-width: 280px;
    color: var(--main-color);
    font-family: "Calibri";
    font-size: 19px;
    line-height: 30px;
    text-transform: capitalize;
}

.hero .splide__slide {
    overflow: hidden;
}

.hero .splide__pagination {
    bottom: 186px !important;
}
.hero .splide__pagination__page.is-active {
    height: 4px;
    width: 50px;
    background: var(--main-color);
    border-radius: 0;
    transform: unset;
}
.hero .splide__pagination__page {
    background: #ffffff;
    height: 4px !important;
    width: 50px !important;
    border-radius: 0;
}

.hero-img {
    position: relative;
}

.hero-image {
    bottom: -50px;
    position: absolute;
    width: 90%;
    height: 90%;
    left: 33%;
    object-fit: contain;
}

@media (max-width: 1400px) {
    .hero {
        height:700px;
    }
}


@media (max-width: 1300px) {
    .hero-content {
        max-width: unset !important;
    }
    .hero-image {
        width: 80% !important;
        height: 73%;
    }
    .hero-sale-title {
        font-size: 36px;
    }
    .hero-sale-para {
        font-size: 17px;
    }
}

@media (max-width: 1200px) {
    /* .hero {
        height: 100vh;
    } */
    .hero {
        height: 625px;
    }
    .hero .splide__pagination {
 bottom: 170px !important;
    }
    .hero-title {
        font-size: 38px;
        margin-bottom: 15px;
    }
    .hero-sub-title{
        font-size: 24px;
        margin-bottom: 18px;
    }
    .hero-btn {
        font-size: 14px;
        padding:8px 18px;
        width: 15%;
    }


    .hero-image {
        width: 80% !important;
        height: 70%;
        bottom: 32%;
        transform: translateY(50%);
    }
    .hero-sale {
        /* position: relative; */
        margin-top: 30px;
        left: unset;
        bottom: 0px;
    }
}

@media (max-width: 992px) {

    .hero {
        height: 400px;
        margin-bottom: -60px;
    }
    .hero .splide__pagination {
        bottom: 73px !important;
    }
    
        .hero-title {
            font-size: 35px;
            margin-bottom: 13px;
        }
    
        .hero-sub-title {
            font-size: 24px;
            margin-bottom: 13px;
        }
    
        .hero-btn {
            width: 22%;
        }
    .hero-image {
        width: 65%  !important;
        height: 70%;
        bottom: 150px;
        left: 45%;
    }
    .hero-content {
        display: flex;
        justify-self: center;
        align-items: center;
        flex-direction: column;
        text-align: center;
    }
    .hero-sale {
        display: flex;
        justify-self: center;
        align-items: center;
        flex-direction: column;
    }
 
    .hero-sale-para {
        /* max-width: unset; */
        font-family: "Calibri Bold";
        /* text-align: center; */
    }
    .hero {
        padding-top: 35px;
    }
    .hero-content-link {
        margin-top: unset !important;
    }
    #hero-bottom {
        display: none;
    }
    .hero-sale {
        /* position: relative; */
        margin-top: 30px;
        left: unset;
        bottom: 70px;
    }
}

@media (max-width: 767px) {
    .hero {
        margin-bottom: -120px;
    }

    .hero .splide__pagination {
        display: none;
    }
    
        .hero-title {
            font-size: 28px;
            margin-bottom: 8px;
        }
    
        .hero-sub-title {
            font-size: 19px;
            margin-bottom: 8px;
        }
    
        .hero-btn {
            font-size: 13px;
            width: 22%;
        }
    .hero-content-title {
        font-size: 36px;
    }
    .hero-content-para {
        font-size: 16px;
    }
    .hero-content-link {
        width: unset !important;
        padding: 12px 42px;
    }
    .hero-image {
        height: 55%  !important; 
        bottom: 130px;
    }
    .hero-sale {
        position: unset;
        margin-top: 30px;
        left: unset;
        bottom: unset;
    }
    #hero-bottom {
        display: none;
    }
    .hero-image {
        left: 20%;
    }
    .hero-sale-para {
        max-width: unset;
        font-family: "Calibri Bold";
        text-align: center;
    }
}
@media (max-width:576px) {
    .hero{
        height: 353px;
        margin-bottom: -140px;
    }
    .hero .splide__pagination {
        bottom: 85px !important;
        display: none;
    }
    
        .hero-title {
            font-size: 23px;
            margin-bottom: 8px;
        }
    
        .hero-sub-title {
            font-size: 15px;
            margin-bottom: 8px;
        }
    
        .hero-btn {
            font-size: 12px;
            width: 25%;
            padding: 7px 5px;
            font-weight: unset;
        }
}