.profile-address-title {
    color: #000;
    font-family: "Inter700";
    font-size: 21px;
}

.profile-address-para {
    color: #000;
    font-family: "Inter400";
    font-size: 16px;
}

.profile-address-info-container {
    border-top: 2px solid rgba(232, 234, 238, 0.40);
    padding-top: 20px;
    margin-top: 20px;
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}

.profile-address-info {
    display: flex;
    align-items: center;
    gap: 9px;
}

.profile-address-name {
    color: #1C1C1C;
    text-align: justify;
    font-family: "Inter700";
    font-size: 14px;
    text-transform: capitalize;
}

.profile-address-desc {
    color: #1C1C1C;
    text-align: justify;
    font-family: "Inter400";
    font-size: 14px;
    text-transform: capitalize;
}

.remove-address, .add-address {
    text-align: justify;
    font-family: "Inter400";
    font-size: 14px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
    text-transform: capitalize;
    border: none;
    outline: none;
    background: none;
}
.add-address {
    color: #1C1C1C;
}
.remove-address {
    color: #FD4B6B;
}

.new-address {
    display: flex;
    align-items: center;
    gap: 7px;
    cursor: pointer;
}
.new-address h1 {
    margin: 0 !important;
}

.new-address-title {
    color: #101728;
    text-align: justify;
    font-family: "Inter400";
    font-size: 14px;
    text-decoration-line: underline;
    text-decoration-style: solid;
    text-decoration-skip-ink: none;
    text-decoration-thickness: auto;
    text-underline-offset: auto;
    text-underline-position: from-font;
}

@media (max-width: 1200px) {
    .remove-address, .add-address, .profile-address-desc, .profile-address-name {
        font-size: 13px;
    }
}