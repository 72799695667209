.trendyProduct {
    margin-top: 120px;
    padding: 180px 0;
    background: url(../../../../asset/images/Home/slider_3.png) no-repeat;
    background-size: cover;
    width: 100%;
}

.trendyProduct-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 30px;
}
.trendyProduct-content p {
    margin-bottom: 0px;
}

.trendyProduct-content h4 {
    color: var(--main-color);
    font-size: 24px;
    font-style: normal;
    font-weight: 300;
}
.trendyProduct-content span {
    font-weight: 700;
}
.trendyProduct-content h1 {
    color: var(--main-color);
    font-size: 47px;
    font-style: normal;
    font-weight: 400;
    text-transform: capitalize;
    margin-bottom: 15px !important;
}
.trendyProduct-content p {
    color: var(--main-color);
    font-size: 22px;
    width: 91%;
    font-style: normal;
    font-weight: 300;
}
.trendyProduct-content a {
    color: var(--main-color);
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    text-transform: uppercase;
    padding: 15px 30px;
    width: 35%;
    border: 1px solid #000;
    text-align: center;
    transition: .3s ease-in-out;
    cursor: pointer;
    border-radius: 4px;
}
.trendyProduct-content a:hover {
    background: #000;
    color: #fff !important;
}
/* .ql-editor h1 {
    margin-top: 0.7em;
    margin-bottom: 0.4em;
} */
.ql-editor h1 {
    margin-top:unset;
    margin-bottom: unset;
}


@media (max-width: 1400px) {
    .trendyProduct {
        padding: 60px 0;
    }  
}


@media (max-width: 1200px) {
    .trendyProduct-content p {
        width: 100%;
    }
 
}

@media (max-width: 992px) {
    .trendyProduct {
        margin-top: 100px;
    } 
    .trendyProduct-content p {
        width: 80%;
        font-size: 20px;
    }

}


@media (max-width: 768px) {
    .trendyProduct {
        padding: 90px 0;
    }
    .trendyProduct-content h1 {
        font-size: 42px;
        margin-bottom: 15px !important;    
    }

    .trendyProduct-content a {
        font-size: 14px;
        padding: 10px 30px;
        width: 30%;
    }
}

@media (max-width: 476px) {
    .trendyProduct {
        padding: 90px 0;
    }
    .trendyProduct-content {
        gap: 15px;
    }
    .trendyProduct-content h4 {
        font-size: 22px;     
    }
    .trendyProduct-content h1 {
        font-size: 32px;     
    }
    .trendyProduct-content p {
        font-size: 18px;
        width: 100%;
    }

    .trendyProduct-content a {
        margin-top: 20px;
        font-size: 16px;
        padding: 11px 30px;
        width: 55%;
    }
}


/* End Trendy Product */