.profile-input-container {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}
.profile-input-container.disabled-input {
    opacity: 0.5;
    pointer-events: none;
}

.profile-input-label {
    color: var(--main-color);
    font-family: "Inter400";
    font-size: 13px;
    letter-spacing: 0.25px;
}

.profile-input {
    width: 100%;
    padding: 9px 15px;
    border-radius: 5px;
    border: 1px solid #D1D1D1;
    background: #FFF;
    color: #000;
    font-family: "Inter400";
    font-size: 13px;
    letter-spacing: 0.25px;
    resize: none;
}
.profile-input::placeholder {
    font-family: "Inter400";
    font-size: 11px;
    letter-spacing: 0.25px
}