.offers {
  margin-top: 120px;
}
.offer-slider .splide__list {
  z-index: 10;
  gap: 9px;
  padding: 25px 0 !important;
}
.offer-slider .splide__arrow {
  width: 50px;
  height: 50px;
  background: #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.18));
}
.offer-slider .splide__arrow--next {
  right: -4rem
}
.offer-slider .splide__arrow--prev {
  display: none;
} 

.offer-content {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
}
.offer-para h1 {
  color: var(--main-color);
  font-family: "Calibri";
  font-size: 48px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}
.offer-para span {
  color: #FD4B6B;
  font-family: "Calibri Bold";
}
.offer-para p {
 margin-bottom: 25px !important;
  color: #858585;
  font-family: "Calibri";
  font-size: 22px;
  font-style: normal;
  font-weight: 300;
  text-transform: capitalize;
}

.card-content .price #last-price,  .card-content .price #last-price span{
  color: #BBB;
  text-decoration: line-through;
}

@media (max-width: 992px) {
  .offer-slider .splide__slide {
      width: unset !important;
  }
  .offer-para h1 {
      font-size: 40px;
  }
  .offer-para p {
    font-size: 20px;
  }
}
@media (max-width: 768px) {
  .offer-content {
      align-items: center;
      text-align: center;
  }
  .card {
      font-size: 5vw !important;
  }
  .flip-clock__slot {
      font-size: 2vw !important;
  }
  .offer-slider .splide__arrow--next {
      display: none;
  }
}



.flip-clock {
  perspective: 400px;
  margin: 20px auto;
  
  *,
  *:before,
  *:after { box-sizing: border-box; }
}

.flip-clock__piece {
  display: inline-block;
  margin: 0 5px;
}

.flip-clock__slot {
  font-size: 1vw;
}


.card {
  display: block;
  position: relative; 
  padding-bottom: 0.72em;
  /* font-size: 9vw; */
  font-size: 3vw; 
  line-height: 0.95;
  border: none;
}

.card__top,
.card__bottom,
.card__back::before,
.card__back::after {
  display: block;
  height: 0.72em;
  color: #ecc77cc7;
  background: #101728f5;
  padding: 0.25em 0.25em;
  border-radius: 0.15em 0.15em 0 0;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden; 
  -moz-backface-visibility: hidden;
  transform-style: preserve-3d;
  width: 1.8em;
  transform: translateZ(0);
}

.card__bottom { 
  color: var(--secondary-color);
  position: absolute;
  top: 50%;
  left: 0;
  border-top: solid 1px #000;
  background: var(--main-color); 
  border-radius: 0 0 0.15em 0.15em; 
  pointer-events: none;
  overflow: hidden;
}

.card__bottom::after {
  display: block;
  margin-top: -0.72em;
}

.card__back::before,
.card__bottom::after {
  content: attr(data-value);
}

.card__back {
  position: absolute;
  top: 0;
  height: 100%;
  left: 0%;
  pointer-events: none;
}

.card__back::before {
  position: relative;
  z-index: -1;
  overflow: hidden;
}

.flip .card__back::before {
  animation: flipTop 0.3s cubic-bezier(.37,.01,.94,.35);
  animation-fill-mode: both;
  transform-origin: center bottom;
}

.flip .card__back .card__bottom {
  transform-origin: center top;
  animation-fill-mode: both;
  animation: flipBottom 0.6s cubic-bezier(.15,.45,.28,1); 
}

@keyframes flipTop {
  0% {
    transform: rotateX(0deg);
    z-index: 2;
  }
  0%, 99% {
    opacity: 0.99;
  }
  100% {
    transform: rotateX(-90deg);
    opacity: 0;
  }
}

@keyframes flipBottom {
  0%, 50% {
    z-index: -1;
    transform: rotateX(90deg);
    opacity: 0;
  }
  51% {
    opacity: 0.99;
  }
  100% {
    opacity: 0.99;
    transform: rotateX(0deg);
    z-index: 5;
  }
}