.category-container {
  padding-top: 65px;
  padding-bottom: 65px;
}


.row {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

.row-padding{
  padding-top: 80px;
}

.card {
  border-radius: 0 !important;
  border-color: transparent !important;
  box-shadow: unset;
}
.category-card{
  height: 190px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 35px 0px #0000000D;


}
.category-cir{
  position: absolute;
  top: -27px;
  left: -22px;
  scale: 0.7;
  z-index: 0;
}

.category-card-img{
  padding: 20px;
  height: 300px;
  width: 85% !important; 
  /* display: block; */
  object-fit: contain;
  position: absolute;
}
.padding-row-category{
  padding-left: 126px;
  padding-right: 126px;
  padding-top: 40px;
}
.margin-col{
  margin-left: 25px;
  margin-right: 25px;
}
.discount{
  position: absolute;
  top: 15px;
  left: 25px;
  background-color: white;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 15px;
  text-transform: uppercase;
  font-weight: 200;
}
.favorite-icon{
  position: absolute;
  right: 22px;
}
.card .category-description{
  text-align: start;
  padding-top: 15px;
}
.category-description .title{
  font-family: 'Calibri';
  font-size: 18px;
  font-weight: 600;
}

.category-description .sub-title{
  font-family: 'Calibri';
  font-size: 13px;
  font-weight: 300;
}

.button-show{
  margin-top: 50px;

  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  letter-spacing: 5.8px;
  text-transform: uppercase;
}
.border-button {
  position: relative;
  border: none;
  padding: 15px 20px;
  background-color: transparent;
}


.border-button::before,
.border-button::after {
  content: "";
  position: absolute;
  width: 30px;
  height: 30px;
  border-style: solid;
}

.border-button::before {
  top: -1px;
  left: -1px;
  border-width: 1px 0 0 1px;
  border-color:  var(--main-color);;
}

.border-button::after {
  bottom: -1px;
  right: -1px;
  border-width: 0 1px 1px 0;
  border-color:  var(--main-color);;
}
.padding-col{
  padding-top: 0px;

}
.margin-card{
  margin-top: 0px;

}




@media (max-width: 1400px) {

}


@media (max-width: 1200px) {

}


@media (max-width: 992px) {
 .category-container{
      margin-top: 65px;
}
.padding-col{
  padding-top: 30px;
  
}
.margin-card{
  margin-top: 30px;

}

}

@media (max-width: 692px){
.padding-col{
  padding-top: 30px;
  
}
.margin-card{
  margin-top: 30px;

}
}  

@media (max-width: 567px) {

}

@media (max-width: 492px) {
}



  
