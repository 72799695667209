.product-review{
  margin-bottom: 40px;
  margin-top: 40px;
}

.product-review .detail {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.product-review .para {
  font-family: Inter;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #292936;
}

.detail .reviwer {
  font-family: Inter;
  font-size: 14px;
  font-weight: 600;
  line-height: 16px;
  color: #63637B;
  padding-left: 10px;
  padding-right: 10px;
}

.detail .time {
  color: #9191A8;
  font-family: Inter;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  padding-right: 10px;

}

.detail .rate {
  color: #1C1C1C;
  font-family: Inter;
  font-size: 13px;
  font-weight: 700;
  line-height: 10px;
}

@media (max-width: 425px) {}

@media (max-width: 1200px) {}

@media (max-width: 992px) {}

@media (max-width: 476px) {}