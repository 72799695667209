.featured-product .title{
  margin-top: 120px !important;
}
.list-slider {
  margin-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
  padding-left: unset !important;
}
.list-slider li {
  border-radius: 5px;
  border: 1px solid var(--main-color);
  color: var(--main-color);
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  padding: 6px 25px;
  cursor: pointer;
  transition: .3s ease-in-out; 
}

.list-slider li.active-link {
  color: var(--secondary-color);
  background: var(--main-color);
}

.active-link {
  color: #CF9F47 !important;
  background: var(--main-color);
}

/* .cards {
  margin-top: 70px;
} */
.cards .splide__list {
  gap: 5px;
  padding: 25px 0 !important;
}
.cards .splide__arrow {
  width: 50px;
  height: 50px;
  background: #fff;
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.18));
}
.cards .splide__arrow--next {
  right: -4rem
}
.cards .splide__arrow--prev {
  left: -4rem;
}

.product-link {
  margin-top: 50px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.product-link a {
  color: var(--main-color);
  font-family: "Calibri";
  font-size: 16px;
  font-style: normal;
  padding: 8px 40px;
  border: 1px solid var(--main-color);
  border-radius: 5px;
  text-transform: capitalize;
  cursor: pointer;
  transition: .3s ease-in-out;
}
.product-link a:hover {
  color: #fff !important;
  background: var(--main-color);
}
@media (max-width: 1250px) {
  .cards .splide__arrow--next {
      right: -2rem
  }
  .cards .splide__arrow--prev {
      left: -3rem;
  }    
}
@media (max-width: 1200px) {
  .cards .splide__arrow--next {
      right: -3rem
  }
  .cards .splide__arrow--prev {
      left: -3rem;
  }    
}
@media (max-width: 768px) {
  .cards .splide__arrow--next, .cards .splide__arrow--prev  {
      display: none;
  }
  .splide__track {
      overflow: visible;
  }
}
@media (max-width: 567px) {
  .cards .splide__slide {
      width: unset !important;
  }
}
