.profile-tab {
    background: #FFFFFF;
    box-shadow: 0px 4px 44px rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    padding: 20px;
    transition: 0.2s all ease-in-out;
    margin-bottom: 14px;
    width: 100%;
    cursor: pointer;
}
.profile-tab:hover .chevron {
    fill:#fff;
}
.profile-tab:hover .tab-title {
    color:#fff;
}
.profile-tab:hover .tab-text {
    color:#fff;
}
.circle-icon {
  position: relative;
  opacity: 2;
}
.profile-tab:hover svg .circle-icon {
    fill:#fff;
    opacity: 2;
}
.profile-tab:hover {
    background-color: var(--main-color);
    filter: drop-shadow(0px 4px 44px rgba(0, 0, 0, 0.06));
}


.profile-tab-flex {
    display: flex;
    gap: 13px;
    align-items: center;
}

.tab-title {
    font-family: "Dmsans500";
    font-size: 14px;
    line-height: 20px;
    color: #181D27;
    padding-bottom: 5px;
}

.tab-text {
    font-family: "Dmsans400";
    font-size: 12px;
    line-height: 16px;
    color: #ABABAB;
}

.chevron-div {
    position: absolute;
    top: 40px;
    right: 18px;
}
.chevron {
    fill:#ABABAB;
}

.profile-tab-svg {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    width: 36px;
    height: 36px;
    background: rgba(128, 128, 128, 0.05);
    transition: 0.2s all ease-in-out;
}
.profile-tab:hover .profile-tab-svg {
    background: rgba(255, 255, 255, 0.11);
} 
.profile-tab:hover .profile-tab-svg svg path {
    stroke: #fff;
}

/* Active Tab */
.profile-tab.active-tab {
    background: var(--main-color);
}
.profile-tab.active-tab .profile-tab-svg svg path {
    stroke: #fff;
}
.profile-tab.active-tab .profile-tab-svg {
    background: rgba(255, 255, 255, 0.11);
} 
.profile-tab.active-tab .tab-title {
    color:#fff;
}
.profile-tab.active-tab .tab-text {
    color:#fff;
}
.profile-tab.active-tab .chevron {
    fill:#fff;
}

@media (max-width: 992px) {
    .profile-tab {
        width: 100%;
    }
}