.loading-profile-spinner {
    width: 100%;
    height: 100%;
    opacity: 0.6;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 10;
    background: #fff;
} 

.spinner-container {
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 11;
    transform: translate(-50%, -50%);
}

.tabs-content-container {
    position: relative !important;
    padding-left: 30px !important;
}

@media (max-width: 1200px) {
    .tabs-container {
        padding-left: unset !important;
        padding-right: unset !important;
    }
    .tabs-content-container {
        padding-right: unset !important;
    }
}

@media (max-width: 992px) {
    .tabs-content-container {
        padding-left: unset !important;
        margin-top: 40px;
    }
}

@media (max-width: 576px) {
    .tabs-content-container {
        margin-bottom: 60px !important;
    }
}