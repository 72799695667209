.aboutus-title {
    color: #101728;
    font-family: "Poppins400";
    font-size: 26px;
}

.aboutus-para {
    margin-top: 20px;
    color: #707070;
    font-family: "Poppins400";
    font-size: 13px;
    line-height: 25px;
}

.top-about-image {
    margin-top: 40px;
    height: 280px;
    width: 100%;
    border-radius: 8px 175.5px 8px 8px;
    background-image: url("../../asset/images/about us/top.png");
    background-size: cover;
    background-repeat: no-repeat;
}

.about-list {
    margin-top: 15px;
    list-style: disc;
    padding-left: 1rem !important;
}

.aboutus-desc {
    color: #707070;
    text-align: justify;
    font-family: "Poppins300";
    font-size: 14px;
    line-height: 28px;
}
.aboutus-desc span {
    font-family: "Poppins600";
    text-transform: uppercase;
}

.about-right-img {
    width: 90%;
    height: 100%;
    background-image: url("../../asset/images/about us/s1.png");
    background-size: cover;
    border-radius: 8px 8px 134px 8px;
}
.about-left-img {
    width: 100%;
    height: 100%;
    background-image: url("../../asset/images/about us/s2.png");
    background-size: cover;
    border-radius: 8px 8px 8px 134px;
}

.about-genius {
    margin-top: 25px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.about-genius-title {
    color: #101728;
    font-size: 50px;
    font-family: "Poppins200";
    letter-spacing: 26px;
    text-transform: uppercase;
    text-align: justify; 
    width: 100%;
}

.about-genius-para {
    color: #707070;
    font-family: "Poppins400";
    font-size: 18px;
    letter-spacing: 6.16px;
    text-transform: uppercase;
    text-align: justify;
    width: 100%;
}


@media (max-width: 1400px) {
    .about-genius-title {
        font-size: 36px;
    }
    .about-genius-para {
        font-size: 14px;
    }
}

@media (max-width: 1200px) {
    .about-genius-title {
        letter-spacing: 21px;
        font-size: 30px;
    }
    .about-genius-para {
        font-size: 13px;
        letter-spacing: 4.16px;
    }
}

@media (max-width: 992px) {
    .about-left-img, .about-right-img {
        height: 250px;
        width: 100%;
        margin-top: 30px;
    }
    .header {
        margin-top: 99px;
    }
}

@media (max-width: 768px) {
    .aboutus-title {
        font-size: 23px;
    }
    .about-genius-title {
        letter-spacing: 18px;
        font-size: 26px;
    }
    .about-genius-para {
        font-size: 12px;
        letter-spacing: 3.16px;
    }
    .aboutus-desc {
        font-size: 13px;
        line-height: 25px;  
    }
    .aboutus-para {
        font-size: 12px;
        line-height: 24px;
        margin-top: 13px;
    }
    .top-about-image {
        height: 220px;
        background-position: center;
    }
}

@media (max-width: 576px) {
    .about-left-img {
        margin-bottom: 60px;
    }
    .aboutus-title {
        font-size: 21px;
    }
    .about-left-img, .about-right-img {
        height: 220px;
    }
    .aboutus-desc {
        font-size: 12px;
        line-height: 23px;  
    }
}

@media (max-width: 476px) {
    .aboutus-title {
        font-size: 18px;
    }
    .aboutus-desc {
        font-size: 11px;
        line-height: 21px;  
    }
    .aboutus-para {
        font-size: 11px;
        line-height: 21px;
        margin-top: 10px;
    }
    .about-left-img, .about-right-img {
        height: 190px;
    }
    .top-about-image {
        height: 190px;
        background-position: center;
    }
    .about-genius-title {
        letter-spacing: 15px;
        font-size: 22px;
    }
    .about-genius-para {
        font-size: 11px;
        letter-spacing: 2.16px;
    }
}