.load-text{
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
}
.load-text p{
  padding-top: 20px;
  color:var(--main-color);
  font-size: 20px;
  font-family: "Quicksand600";
}

.loader {
  width: 75px;
  aspect-ratio: 1; 
  display: grid;
}
.loader:before,
.loader:after {
  content: "";
  grid-area: 1/1;
  width: 35px;
  aspect-ratio: 1;
  box-shadow: 0 0 0 3px var(--secondary-color) inset;
  filter: drop-shadow(40px 40px 0 var(--main-color));
  animation: l8 2s infinite alternate;
}
.loader:after {
  margin: 0 0 0 auto; 
  filter: drop-shadow(-40px 40px 0 var(--main-color));
  animation-delay: -1s;
}
@keyframes l8 {
  0%,10%   {border-radius:0}
  30%,40%  {border-radius:50% 0}
  60%,70%  {border-radius:50%}
  90%,100% {border-radius:0 50%}
}