.navbar {
    background: #fff;
    padding: 15px 0 65px !important;
    z-index: 12 !important;
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
}

.navbar-scroll {
    padding: 15px 0 15px !important;
}

.navbar-scroll .searching {
    display: none;
}

.navbar-scroll .responsive-nav {
    display: flex;
    flex-direction: row-reverse;
}

.navbar-scroll .down-nav {
    position: unset;
}

.navbar-scroll .up-nav-scroll {
    margin-left: 90px;
    width: unset;
}

.navbar-scroll .nav-link {
    font-size: 13px !important;
}

.navbar-brand img {
    width: 180px;
}
/* .navbar-brand {
    margin-right: unset;
    margin-left: 6px;
} */
.links {
   display: flex;
   align-items: center;
}

.nav {
    position: relative;
}

.searching {
    display: flex;
    margin-left: 150px;
    border: 1px solid #909090;
    border-radius: 4px;
}

.responsive-nav {
    width: 100%;
}



.up-nav {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.up-nav-scroll {
    margin-left: 370px;

}

.up-nav-scroll::after {
    display: none;
}

.up-nav::after {
    position: absolute;
    content: '';
    width: 200%;
    height: 1px;
    background: #B6B6B6;
    bottom: -20px;
    left: -50%;
}

.searching input[type="text"] {
    font-size: 13px;
    background: none;
    outline: none;
    border: 1px solid #fff;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: none;
    margin-left: -5px;
    color: #fff;
    width: 350px;
}

.searching input[type="text"]::placeholder {
    text-transform: capitalize;
    font-size: 14px;
    color: #ccc;
    font-style: normal;
    font-weight: 300;
}

.searching .dropdown-toggle {
    position: relative;
    background: none;
    font-size: 13px !important;
    border-radius: unset;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #fff;
    border-right: none;
    text-transform: capitalize !important;
}

.searching .dropdown-toggle::before {
    content: '';
    position: absolute;
    width: 1px;
    height: 50%;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    background: #fff;
    z-index: 1;
}

.searching .dropdown-toggle,
.searching input[type="text"] {
    padding: 9px 10px;
}

.searching-input-scroll {
    padding: 7px 10px !important;
    width: 243px !important;
}

.btn-search-scroll {
    padding: 7px 10px !important;
}

.dropdown-scroll a {
    padding: 7px 10px !important;
}

.dropdown-toggle::after {
    color: #101728;
}

.searching #search {
    background-color: #101728;
    padding: 0px 20px;
    border-left: none;
    border: none;
    outline: none;
    margin-left: -5px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: center;
}

.searching i {
    color: #fff;
    font-size: 20px;
}

.settings {
    display: flex;
    align-items: center;
    gap: 10px;
}

.settings-seperator {
    width: 1px;
    height: 14px;
    background-color: #B4B4B4;
}

.settings-content {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.settings-content img {
    cursor: pointer;
    width: 15px;
}

.settings-content #basket {
    width: 32px;
}

.settings-content h1 {
    cursor: pointer;
    margin: 0;
    color: #FFF;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.down-nav {
    position: absolute;
    left: 0;
    right: 0;
    top: 80px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 1;
    transition: .5s ease-in-out;
}

.down-nav-scroll {
    left: 200px;
    top: 8px;
}

.country {
    display: flex;
    align-items: center;
}

.country-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.country-content:not(:first-child) {
    margin-left: 25px;
}

.country-content h1 {
    cursor: pointer;
    margin: 0;
    color: #FFF;
    font-family: "Inter";
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
}

.country-content:first-child img {
    width: 27px;
}

.country-content:last-child img {
    width: 20px;
}

.nav-item {
    position: relative;
}

.nav-item:not(:first-child) {
    /* margin-left: 12px !important; */
}


.nav-link {
    position: relative;
    color: #101728 !important;
    font-family: "Poppins400";
    font-size: 13px !important;
    font-style: normal;
    font-weight: 400;
}

/* .active {
    color: var(--secondary-color) !important;
} */

.basket-number {
    font-size: 10px;
    background: #FD4B6B;
    color: #fff;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-status {
    position: absolute;
    top: -6px;
    right: 0px;
    background: #FD4B6B;
    color: #fff;
    font-size: 10px;
    border-radius: 20px;
    padding: 0px 6px;
}


@media(max-width: 1400px) {
    .down-nav-scroll .country-content {
        gap: 7px;
    }

    .up-nav-scroll {
        margin-left: 280px;
    }

    .down-nav-scroll .country-content:not(:first-child) {
        margin-left: 15px;
    }
}

@media(max-width: 1200px) {
    .searching {
        margin-left: unset;
    }

    .searching input[type="text"] {
        width: 325px;
        font-size: 14px;
    }

    .searching .dropdown-toggle,
    .searching input[type="text"] {
        padding: 14px 20px;
    }

    .searching .dropdown-toggle {
        font-size: 14px;
    }

    .searching #search {
        padding: 11px 13px;
    }
    
    .settings-content h1 {
        font-size: 14px;
    }

    .settings-content img {
        width: 22px;
    }

    .settings-content #basket {
        width: 32px;
    }

    .navbar-scroll .navbar-brand img {
        width: 155px;
    }

    .down-nav-scroll {
        left: 160px;
        top: 2px;
    }

    .down-nav-scroll .country-content {
        gap: 5px;
    }

    .up-nav-scroll {
        margin-left: 280px;
    }

    .down-nav-scroll .country-content:not(:first-child) {
        margin-left: 10px;
    }

    .searching-input-scroll {
        width: 145px !important;
    }

    .btn-search-scroll {
        padding: 6px 10px !important;
    }
}

@media(max-width: 992px) {
    .navbar-scroll .responsive-nav {
        display: unset;
    }
    .navbar-scroll .up-nav-scroll {
        margin-left: -17px;
    }
    .nav-item:not(:first-child) {
        margin-left: unset !important;
    }

    .navbar {
        padding: 20px 0 !important;
    }

    .responsive-nav {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
    }

    .down-nav {
        position: relative;
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        padding: 40px 0 20px;
        top: unset;
        left: unset;
    }

    .navbar-toggler {
        border: 2px solid var(--secondary-color) !important;
        box-shadow: unset !important;
        padding: 7px 16px;
        border-radius: 5PX;
    }

    /* .navbar-toggler-icon {
        background: url("../../asset/images/svg/icons8-menu-bar.svg") no-repeat center !important;
    } */

    .up-nav {
        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        gap: 24px;
        margin-top: 20px;
        width: 100%;
    }

    .searching {
        width: 100%;
    }

    .searching .dropdown-toggle,
    .searching input[type="text"] {
        width: 100%;
    }

    .nav-link {
        text-align: start !important;
    }

    .up-nav-scroll {
        margin-left: unset;
    }

    .settings-scroll {
        display: flex;
    }

    .up-nav-scroll::after {
        display: flex;
    }

    .searching-input-scroll {
        width: 100% !important;
    }
    .sale-div {
        display: none;
    }


}

@media (max-width: 576px) {
    .nav-link {
        margin-left: 10px !important;
    }

    .country-content {
        margin-left: 10px !important;
    }

    .dropdown-scroll .dropdown-toggle::before {
        right: 10px;
    }
    .navbar {
        padding: 20px 20px !important;
    }
}




/* container Products Page */

.navbar.nav-products .nav {
    padding-left: calc(var(--bs-gutter-x)* .5) !important;
}
.navbar.nav-products .down-nav {
    left: calc(var(--bs-gutter-x)* .5) !important;
}

@media (min-width: 576px) {
    .navbar.nav-products .container  {
      max-width: 560px; /* Slightly bigger than default 540px */
    }
  }
  
  @media (min-width: 768px) {
    .navbar.nav-products .container  {
      max-width: 760px; /* Slightly bigger than default 720px */
    }
  }
  
  @media (min-width: 992px) {
    .navbar.nav-products .container  {
      max-width: 980px; /* Slightly bigger than default 960px */
    }
  }
  
  @media (min-width: 1200px) {
    .navbar.nav-products .container  {
      max-width: 1200px; /* Slightly bigger than default 1140px */
    }
  }
  
  @media (min-width: 1400px) {
    .navbar.nav-products .container  {
      max-width: 1400px; /* Slightly bigger than default 1320px */
    }
  }
  
  @media (min-width: 1500px) {
    .navbar.nav-products .container  {
      max-width: 1500px; /* Slightly bigger than default 1320px */
    }
  }
  
  @media (min-width: 1600px) {
    .navbar.nav-products .container  {
      max-width: 1430px; /* Slightly bigger than default 1320px */
    }
  }
  
  @media (min-width: 1700px) {
    .navbar.nav-products .container  {
      max-width: 1700px; /* Slightly bigger than default 1320px */
    }
  }
    


/* container Products Page */