footer {
    margin-top: 60px;
    border-top: 1px solid #BEBEBE;
    background: #FBFBFB;
    color: #444444;
    padding: 20px;
}
.footer-content {
    padding-top: 100px;
    display: flex;
    justify-content: space-between;
    align-items: start;
}
.footer-text {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    gap: 10px;
}
.footer-text img {
    width: 150px;
    margin-bottom: 15px;
}

.footer-text-title {
    color: #101728;
    font-family: "Poppins700";
    font-size: 14px;
}

.footer-text p {
    max-width: 290px;
}


#footer-logo img {
    width: 180px;
    margin-top: -16px;
}

#footer-logo h1 {
    color: #FFF;
    font-family: "Inter";
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    letter-spacing: 1.3px;
    text-transform: uppercase;
}

#footer-logo p {
    color: #444;
    text-align: justify;
    font-family: "Poppins400";
    font-size: 13px;
    line-height: 22px;
}
#footer-touch i {
    margin-right: 8px;
}
#footer-touch p {
    cursor: unset;
    margin-bottom: 20px;
}

.footer-touch-text {
    display: flex;
    justify-content: center;
    align-items: center;
}
.footer-touch-email {
    margin-top: 15px;
}
.footer-touch-email input[type="email"] {
    width: 180%;
    background: none;
    border: none;
    border-bottom: 1px solid #898989;
    outline: none;
    padding: 10px 0;
    margin-bottom: 25px;
    color: #fff;
    font-size: 12px;
}
.footer-touch-email input[type="email"]::placeholder {
    color: #898989;
}

.tappay {
    background: #A1DEDE;
    padding:10px 20px;
    border: none;
    border-radius: 8px;
}
.tappay-img {
    margin-bottom: unset !important;
    width:unset !important;
}
footer a:hover {
    color: var(--secondary-color);
} 
#footer-touch a {
    margin-left: 130px;
    border: 1px solid #fff;
    color: #fff;
    padding: 8px 20px;
    letter-spacing: 1px;
    background: var(--main-color);
    font-family: 'Inter', sans-serif;
    font-size: 10px;
    font-weight: 300;
    text-transform: uppercase;
    transition: .4s ease-in-out;
}
#footer-touch a:hover {
    background: #fff;
    color: var(--main-color);
    font-weight: 400;
}
.footer-text button {
    border: none;
}



.footer-down {
    width: 100%;
    margin: 40px auto;
    border-top: 1px solid #CACACA;
}
.footer-links {
    padding-top: 40px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
}
.links-text {
    display: flex;
    gap: 20px;
}
.links-text p {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    margin-right: 20px;
    cursor: pointer;
    transition: .3s ease-in-out;
}
.links-text p:hover {
    color: var(--secondary-color);
}
.footer-down .links {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}
.links svg {
    /* margin-left: 15px; */
    width: 23px;
    cursor: pointer;
    transition: .3s ease-in-out;
    fill: #101728;
}
.links svg:hover {
    transform: scale(1.1);
    fill: var(--secondary-color);
}
.footer-copyright {
    max-width: 400px;
    text-align: center;
}
.footer-down-para { 
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px;
}
.footer-down-para p {
    font-family: 'Inter300';
    font-style: normal;
    font-size: 16px;
    line-height: 25px;
    text-align: center;
    color: #FFFFFF;
    width: 55%;
}

.footer-text-para {
    color: #444;
    font-family: "Poppins400";
    font-size: 15px;
    text-transform: capitalize;
    transition: .3s ease;
}
.footer-text-para:hover {
    color: var(--secondary-color);
}

.footer-down-links {
    color: #101728;
    font-family: "Poppins400";
    font-size: 15px;
}

.footer-copytight {
    color: #101728;
    font-family: "Poppins300";
    font-size: 15px;
}
.footer-copytight span {
    font-family: "Poppins600";
}

@media (max-width: 1200px) {
    .footer-text-para {
        font-size: 13px !important;
    }
    .footer-text p {
        max-width: 270px;
    }
    #footer-logo p {
        font-size: 12px;
    }
    .footer-down-links {
        font-size: 14px;
    }
    .footer-copytight {
        font-size: 14px;
    }
}

@media (max-width: 992px) {
    .footer-content {
        padding-top: 50px;
        display: flex;
        justify-content: space-between;
        align-items: start;
        flex-wrap: wrap;
        gap: 25px
    }
    #footer-logo {
        width: 100%;
    }
    #footer-logo p {
        max-width: none;
    }
    .footer-text {
        margin: 30px 10px 10px;
    }
}

@media (max-width: 768px) {
    .footer-down {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
    .footer-links {
        justify-content: center;
        align-items: center;
        flex-direction: column;
        gap: 20px;
    }
    .links-text p {
        margin: unset;
    }
    .footer-down-para p {
        width: 81%;
    }
} 
@media (max-width: 497px) {
    .footer-content {
        justify-content: flex-start;
    }
}
@media (max-width: 576px) {
    footer {
        margin-top: 0px;
        padding: 20px 0px;
    }
}