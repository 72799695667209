.filters {
  width: 190px;
}

.filter {

}

.filter-header {
}

.filter-title {
  font-family: "Inter300";
  font-size: 13px;
  line-height: 20px;
  letter-spacing: 0.09em;
  color: #303030;
}

.divider {
  border: 1px solid #EEEEEE;
  margin-top: 15px;
  margin-bottom: 15px;
}

.filter-sub-title {
  font-family: "Inter300";
  font-size: 11px;
  letter-spacing: 0.09em;
  color: #303030;
  text-transform: uppercase;
}

.filter-search {
  margin-top: 10px;
  padding: 5px 14px;
  border: 1px solid #E6E6E6;
  width: 100%;
  font-family: "Inter400";
  font-size: 12px;
  letter-spacing: 0.09em;
}
.filter-search::placeholder {
  color: #E6E6E6;
  font-family: "Inter400";
  font-size: 12px;
  letter-spacing: 0.09em;
}


.brand-filter {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
}

.check-items {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 10px;
}
.brand-filter .check-items:not(:first-child) {
  margin-top: 15px;
}
.check-items-label {
  color: #303030;
  font-family: "Inter400";
  font-size: 10px;
  letter-spacing: 1.15px;
  text-transform: capitalize;
}

.more-brands {
  font-family: Inter;
  font-size: 9px;
  font-weight: 400;
  line-height: 9px;
  letter-spacing: 0.09em;
  color: #303030;
  text-decoration: underline;
  margin-top: 10px;

}

.accordion-products {
  margin-top: 10px;
  box-shadow: unset !important;
}
.accordion-products::before {
  display: none !important;
}
.accordion-products-summary {
  padding: 0 0 0 30px !important;
  min-height: unset !important;
}
.accordion-products-summary span {
  margin-left: 10px;
  font-family: "Inter600";
  font-size: 14px;
  letter-spacing: 0.352px;
}
.accordion-products-summary div:first-child {
  margin: unset !important;
}


.checkbox-label-products {
  color: var(--Gray-100, #19191D);
  font-family: "Inter400";
  font-size: 13px;
  letter-spacing: 1.25px;
}
/* Hide the default checkbox */
/* .check-items input[type="checkbox"] {
  display: none;
} */

/* .check-items input[type="checkbox"] + span:before {
  content: "";
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid #D2D2D2; 
  background-color: white;
  margin-right: 5px;
  vertical-align: middle;
} */

/* input[type="checkbox"] { 
  filter: invert(100%) hue-rotate(18deg) brightness(1.7);
} */

/* .check-items input[type="checkbox"]:checked + span:before:after {
  content: "";
  position: absolute;
  top: 4px;
  left: 4px;
  width: 8px;
  height: 8px;
  background-color: black;
} */

/* .check-items input[type="checkbox"]::-moz-focus-inner {
  border: 0;
  padding: 0;
  margin: 0;
} */

.checkbox-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.checkbox-label-products {
}

.checkbox-products svg {
  width: 20px;
  height: 20px;
}

.checkbox-products {
  width: 20px;
  height: 20px;
  border: unset !important; /* Add border */
  border-radius: 0px !important; /* Optional: Rounded corners */
}


@media (max-width: 1400px) {
  .filters {
    width: 170px;
  }
}

@media (max-width: 1200px) {
  .filters {
    width: 140px;
  }
}


@media (max-width: 992px) {}

@media (max-width: 692px) {}

@media (max-width: 567px) {}

@media (max-width: 492px) {}