.register {
    margin-top: 120px;
    margin-bottom: -60px;
}

.register-container {
    position: relative;
}

.reg-title {
    color: #000000;
    font-size: 15px !important;
    font-weight: 500 !important;
    line-height: 28px !important;
    letter-spacing: 0.25px !important;
    text-align: left;
}

.reg-input {
    background: #FFFFFF !important;
    border: 1px solid #D1D1D1 !important;
    border-radius: 5px !important;
    padding: 5px 18px !important;
    font-style: normal !important;
    color: #000000;
    font-family: Inter;
    font-size: 15px !important;
    font-weight: 400 !important;
    line-height: 28px !important;
    letter-spacing: 0.25px !important;
}


.flag-container {
    margin-top: 6px;
    margin-bottom: 6px;
}

.intl-tel-input {
    width: 100%;
}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-flag {
    background-color: unset !important;
    display: flex !important;
    border-right: 1px solid #E5E5E5 !important;

}

.intl-tel-input.allow-dropdown.separate-dial-code .selected-dial-code {
    font-size: 12px !important;
    padding-left: 5px !important;

}

.intl-tel-input.allow-dropdown input,
.intl-tel-input.allow-dropdown input[type=tel] {
    color: #000000 !important;
    font-family: Inter !important;
    font-size: 12px !important;
    line-height: 28px !important;
}

.dashed-line-extra-marg {
    margin-bottom: 25px !important;
    margin-top: 25px;
}

.dashed-line {
    margin-bottom: 15px;
}

@media (max-width: 1400px) {}


@media (max-width: 1200px) {}


@media (max-width: 992px) {
    .register {
        margin-top: 90px;
    }

    .reg-title {
        font-size: 12px !important;
    }

    .reg-input {
        font-size: 12px !important;
    }
}

@media (max-width: 692px) {}

@media (max-width: 567px) {
    .register {
        margin-bottom: -60px;
    }
}

@media (max-width: 492px) {}